import { Menu } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import swal from 'sweetalert'

// Icons Import
import { AiOutlineProject } from 'react-icons/ai'
import { BiTime } from 'react-icons/bi'
import { FiAlignCenter, FiHome, FiSettings, FiShield, FiUsers } from 'react-icons/fi'
import { GiPayMoney } from 'react-icons/gi'
import { MdOutlineAddModerator, MdOutlineEditCalendar } from 'react-icons/md'
import { TbLogout, TbReport } from 'react-icons/tb'
import { HiSpeakerphone, HiOutlineTicket } from 'react-icons/hi'
import { FaHireAHelper } from 'react-icons/fa'

const MainMenu = ({ active }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = localStorage.getItem('userType')
  const moderator = JSON.parse(localStorage.getItem('moderator'))
  const checkIn = localStorage.getItem('checkinId')
  const roles = moderator?.roles?.roles
  const { socket } = useSelector((state) => state.socket)
  useEffect(() => {}, [roles])

  return (
    <Menu
      theme='dark'
      mode={'inline'}
      defaultSelectedKeys={[active]}
      style={{
        background: 'var(--sidebar)',
        minHeight: '83vh',
      }}
    >
      {userType === 'admin' && (
        <>
          <Menu.Item
            key='admin-dashboard'
            className={'sidebar-menu'}
            icon={<FiHome className='menu-icon' />}
            onClick={() => history.push('/admin/dashboard')}
          >
            {t('dashboard')}
          </Menu.Item>
          <Menu.Item
            key='admin-projects'
            className={'sidebar-menu'}
            icon={<AiOutlineProject className='menu-icon' />}
            onClick={() => history.push('/admin/projects')}
          >
            {t('projects')}
          </Menu.Item>
          <Menu.Item
            key='admin-employees'
            className={'sidebar-menu'}
            icon={<FiUsers className='menu-icon' />}
            onClick={() => history.push('/admin/employees')}
          >
            {t('employees')}
          </Menu.Item>
          <Menu.Item
            key='admin-expense'
            className={'sidebar-menu'}
            icon={<GiPayMoney className='menu-icon' />}
            onClick={() => history.push('/admin/expenses')}
          >
            {t('expenses')}
          </Menu.Item>
          <Menu.Item
            key='admin-report'
            className={'sidebar-menu'}
            icon={<TbReport className='menu-icon' />}
            onClick={() => history.push('/admin/reports')}
          >
            {t('reports')}
          </Menu.Item>
          {/* <Menu.Item
            key='admin-moderator'
            className={'sidebar-menu'}
            icon={<MdOutlineAddModerator className='menu-icon' />}
            onClick={() => history.push('/admin/moderators')}
          >
            {t('moderators')}
          </Menu.Item> */}

          <Menu.Item
            key='checkins'
            className={'sidebar-menu'}
            icon={<BiTime className='menu-icon' />}
            onClick={() => history.push('/admin/checkins')}
          >
            {t('checkins')}
          </Menu.Item>
          <Menu.Item
            key='attendance'
            className={'sidebar-menu'}
            icon={<FiUsers className='menu-icon' />}
            onClick={() => history.push('/admin/attendance')}
          >
            {t('attendance')}
          </Menu.Item>
          {/* <Menu.Item
            key='admin-leave'
            className={'sidebar-menu'}
            icon={<MdOutlineEditCalendar className='menu-icon' />}
            onClick={() => history.push('/admin/leave')}
          >
            {t('leave')}
          </Menu.Item>
          <Menu.Item
            key='admin-annoucement'
            className={'sidebar-menu'}
            icon={<HiSpeakerphone className='menu-icon' />}
            onClick={() => history.push('/admin/announcement')}
          >
            {t('announcement')}
          </Menu.Item>
          <Menu.Item
            key='admin-hr'
            className={'sidebar-menu'}
            icon={<FaHireAHelper className='menu-icon' />}
            onClick={() => history.push('/admin/hr')}
          >
            {t('hr')}
          </Menu.Item>
          <Menu.Item
            key='roles'
            className={'sidebar-menu'}
            icon={<FiShield className='menu-icon' />}
            onClick={() => history.push('/admin/roles')}
          >
            {t('roles')}
          </Menu.Item>
          <Menu.Item
            key='logs'
            className={'sidebar-menu'}
            icon={<FiAlignCenter className='menu-icon' />}
            onClick={() => history.push('/admin/logs')}
          >
            {t('logs')}
          </Menu.Item> */}
          {/* <Menu.Item
            key='accessories'
            className={'sidebar-menu'}
            icon={<AiFillCustomerService className='menu-icon' />}
            onClick={() => history.push('/admin/accessories')}
          >
            Accessories
          </Menu.Item> */}
          <Menu.Item
            key='settings'
            className={'sidebar-menu'}
            icon={<FiSettings className='menu-icon' />}
            onClick={() => history.push('/admin/settings')}
          >
            Settings
          </Menu.Item>
        </>
      )}

      {userType === 'moderator' && (
        <>
          <Menu.Item
            key='checkin'
            className={'sidebar-menu'}
            icon={<BiTime className='menu-icon' />}
            onClick={() => history.push('/moderator/checkin')}
          >
            {t('checkin')}
          </Menu.Item>
          {roles?.includes('dashboard') !== false && (
            <Menu.Item
              key='admin-dashboard'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<FiHome className='menu-icon' />}
              onClick={() => history.push('/admin/dashboard')}
            >
              {t('dashboard')}
            </Menu.Item>
          )}
          {(roles?.includes('projects') !== false ||
            roles?.includes('add-projects') !== false ||
            roles?.includes('project-summary') !== false) && (
            <Menu.Item
              key='admin-projects'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<AiOutlineProject className='menu-icon' />}
              onClick={() => history.push('/admin/projects')}
              // onClick={() => <Redirect to='/admin/projects' />}
            >
              {t('projects')}
            </Menu.Item>
          )}
          {(roles?.includes('employees') !== false ||
            roles?.includes('add-employee') !== false ||
            roles?.includes('employee-summary') !== false ||
            roles?.includes('update-employee') !== false) && (
            <Menu.Item
              key='admin-employees'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<FiUsers className='menu-icon' />}
              onClick={() => history.push('/admin/employees')}
            >
              {t('employees')}
            </Menu.Item>
          )}
          {(roles?.includes('expenses') !== false ||
            roles?.includes('add-expense') !== false ||
            roles?.includes('update-expense') !== false) && (
            <Menu.Item
              key='admin-expense'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<GiPayMoney className='menu-icon' />}
              onClick={() => history.push('/admin/expenses')}
            >
              {t('expenses')}
            </Menu.Item>
          )}
          {roles?.includes('reports') !== false && (
            <Menu.Item
              key='admin-report'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<TbReport className='menu-icon' />}
              onClick={() => history.push('/admin/reports')}
            >
              {t('reports')}
            </Menu.Item>
          )}
          {(roles?.includes('moderators') !== false || roles?.includes('add-moderator')) && (
            <Menu.Item
              key='admin-moderator'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<MdOutlineAddModerator className='menu-icon' />}
              onClick={() => history.push('/admin/moderators')}
            >
              {t('moderators')}
            </Menu.Item>
          )}
          {(roles?.includes('checkins') !== false ||
            roles?.includes('view-checkins') !== false ||
            roles?.includes('view-snaps')) && (
            <Menu.Item
              key='checkins'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<BiTime className='menu-icon' />}
              onClick={() => history.push('/admin/checkins')}
            >
              {t('checkins')}
            </Menu.Item>
          )}
          {roles?.includes('attendance') !== false && (
            <Menu.Item
              key='attendance'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<FiUsers className='menu-icon' />}
              onClick={() => history.push('/admin/attendance')}
            >
              {t('attendance')}
            </Menu.Item>
          )}
          {roles?.includes('leave') !== false && (
            <Menu.Item
              key='admin-leave'
              className={'sidebar-menu'}
              disabled={checkIn ? false : true}
              icon={<MdOutlineEditCalendar className='menu-icon' />}
              onClick={() => history.push('/admin/leave')}
            >
              {t('leave')}
            </Menu.Item>
          )}
          {roles?.includes('announcement') !== false && (
            <Menu.Item
              key='admin-announcement'
              className={'sidebar-menu'}
              disabled={checkIn ? false : true}
              icon={<HiSpeakerphone className='menu-icon' />}
              onClick={() => history.push('/moderator/announcement')}
            >
              {t('announcement')}
            </Menu.Item>
          )}
          {roles?.includes('roles') !== false && (
            <Menu.Item
              key='roles'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<FiShield className='menu-icon' />}
              onClick={() => history.push('/admin/roles')}
            >
              {t('roles')}
            </Menu.Item>
          )}
          {roles?.some((v) => v?.includes('hr-')) && (
            <Menu.Item
              key='admin-hr'
              disabled={checkIn ? false : true}
              className={'sidebar-menu'}
              icon={<FaHireAHelper className='menu-icon' />}
              onClick={() => history.push('/admin/hr')}
            >
              {t('hr')}
            </Menu.Item>
          )}
        </>
      )}

      {userType === 'employee' && (
        <>
          <Menu.Item
            key='checkins'
            className={'sidebar-menu'}
            icon={<BiTime className='menu-icon' />}
            onClick={() => history.push('/employee/checkin')}
          >
            {t('checkin')}
          </Menu.Item>
          <Menu.Item
            key='employee-checkinNew'
            className={'sidebar-menu'}
            icon={<BiTime className='menu-icon' />}
            onClick={() => history.push('/employee/checkinNew')}
          >
            {t('CheckIn New')}
          </Menu.Item>
          <Menu.Item
            key='admin-projects'
            className={'sidebar-menu'}
            icon={<AiOutlineProject className='menu-icon' />}
            onClick={() => history.push('/employee/projects')}
          >
            {t('projects')}
          </Menu.Item>
          {/* <Menu.Item
            key='Todos'
            className={'sidebar-menu'}
            icon={<AiOutlineBook className='menu-icon' />}
            onClick={() => history.push('/todo')}
          >
            {t('todos')}
          </Menu.Item> */}
          <Menu.Item
            key='employee-summary'
            className={'sidebar-menu'}
            icon={<FiUsers className='menu-icon' />}
            onClick={() => history.push('/employee/summary')}
          >
            {t('summary')}
          </Menu.Item>
          <Menu.Item
            key='employee-leave'
            className={'sidebar-menu'}
            icon={<MdOutlineEditCalendar className='menu-icon' />}
            onClick={() => history.push('/leave')}
          >
            {t('leave')}
          </Menu.Item>
          <Menu.Item
            key='admin-annoucement'
            className={'sidebar-menu'}
            icon={<HiSpeakerphone className='menu-icon' />}
            onClick={() => history.push('/announcement')}
          >
            {t('announcement')}
          </Menu.Item>
          <Menu.Item
            key='employee-ticket'
            className={'sidebar-menu'}
            icon={<HiOutlineTicket className='menu-icon' />}
            onClick={() => history.push('/ticket')}
          >
            {t('ticket')}
          </Menu.Item>
        </>
      )}

      <Menu.Item
        key='logout'
        className={'sidebar-menu logout-menu'}
        icon={<TbLogout className='menu-icon' />}
        onClick={() => {
          if (
            (localStorage.getItem('userType') === 'employee' ||
              localStorage.getItem('userType') === 'moderator') &&
            localStorage.getItem('checkinId')
          ) {
            return swal({
              title: 'Warning',
              text: 'You are currently checked in. You will have to check out in order to proceed.',
              icon: 'warning',
            })
          }
          localStorage.clear()
          socket.emit('logout')
          history.push('/')
        }}
        style={{
          position: userType === 'admin' || userType === 'moderator' ? 'relative' : 'absolute',
          bottom: userType === 'admin' || userType === 'moderator' ? '-16px' : '16px',
        }}
      >
        {t('logout')}
      </Menu.Item>
    </Menu>
  )
}

export default MainMenu
