import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { RoleCheck } from '../functions/RoleCheck'

const PrivateRoute = ({ component: Component, ...rest }) => {
  var id = localStorage.getItem('token')
  const Usertype = localStorage.getItem('userType')
  const LocalRoles = JSON.parse(localStorage.getItem('moderator'))
  const roles = LocalRoles?.roles?.roles
  // console.log('roles inside private routes', roles)

  return (
    <Route
      {...rest}
      render={(props) => {
        return id && localStorage.getItem('token') ? (
          Usertype === 'moderator' ? (
            RoleCheck(props?.location?.pathname) ? (
              <Component {...props} />
            ) : (
              // <Redirect to={`/admin/${roles?.[0]}`} />
              // <Redirect to={'/moderator/checkin'} />
              <Redirect to={'/moderator/checkin'} />
            )
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to='/' />
        )
      }}
    />
  )
}

export default PrivateRoute
