import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Input,
  Modal,
  Form,
  Row,
  Select,
  Table,
} from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
import { IoChevronDownSharp, IoChevronUp } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
// import swal from 'sweetalert'
import InfinityLoader from '../components/InfinityLoader'
import { getTimeInHoursMins } from '../functions/timeFormatter'
import Layout from '../layout/Layout'
import {
  _projectCheckIn,
  addOfflineScreenshot,
  // addScreenshot,
  endTask,
  getAllEmployeeProjects,
  projectCheckOut,
  getCheckins,
  // getUserTimelineScreenshots,
  startTask,
} from '../redux'
import dataURItoBlob from '../service/dataURItoBlob'
import '../styles/pages/checkins.css'
import ProjectCheckIn from './ProjectCheckIn'
import { getCheckin, getCheckinProject, getSelectedProject } from '../functions/globals'
import CheckInHistoryComponet from '../components/CheckInHistory'

const Checkins = ({
  intervalState,
  videoEL,
  TrackState,
  setIntervalState,
  setVideoEl,
  setTrackState,
  projectTitle,
  setProjectTitle,
  defaultValue,
  condition,
  setCondition,
  isShift,
  setIsShift,
  data,
  setData,
  checkinHistory,
  setCheckinHistory,
}) => {
  // const projects = useSelector(state => state.projects.projects);
  // const projects = useSelector(state => state.projects);
  const [projectId, setProjectId] = useState(localStorage.getItem('project'))
  const [checkinId, setCheckinId] = useState(localStorage.getItem('checkinId'))

  const [projectCheckInId, setProjectCheckInId] = useState(localStorage.getItem('projectCheckInId'))
  // const [forceRender, setForceRender] = useState(false)
  // const [checkinHistory , setCheckinHistory] = useState(false)

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [selectedProject, setSelectedProject] = useState(projectTitle)
  const [date, setDate] = useState({
    start: null,
    end: null,
  })
  const dispatch = useDispatch()
  const { projects } = useSelector((state) => state.project)
  const userId = localStorage.getItem('userId')
  const checkins = useSelector((state) => state.shiftCheckin.checkins)
  const [currentCheckIn, setCurrentCheckIn] = useState(null)
  const { socket } = useSelector((state) => state.socket)
  const [timer, setTimer] = useState(null)
  const [form] = Form.useForm() // Initialize
  const [btnLoading, setBtnLoading] = useState(false)
  const [remarksModal, setRemarksModal] = useState(false)
  useEffect(() => {
    // console.log('use-Effect projectTitle projectTitle projectTitle',projectTitle)
    if (projectTitle) {
      // console.log('selectedProject if - - - - - - - - - - - - - - -')
      setCheckinId(getCheckin())
      setSelectedProject(projectTitle?.title)
    }
    if (!projectTitle) {
      // console.log('selectedProject',defaultValue)
      setSelectedProject(false)
      setCheckinId(getCheckin())
    }
  }, [projectTitle])
  let intRun = false
  const intRunChange = (val) => {
    intRun = val
    if (val == false) {
      // clearinterval
    }
  }

  // useEffect(() => {

  //   console.log("CheckIn History",checkinHistory)
  // }, [checkinHistory])

  useEffect(() => {
    console.log('use-Effect Get ALL employees parent')

    if (localStorage.getItem('token')) {
      dispatch(getAllEmployeeProjects())
    }
  }, [dispatch])

  const updateData = (d) => {
    console.log('d - = - = - = - >', d)
    setData(d)
  }

  const fetchCheckins = async () => {
    console.log('fetch check In Start in CheckIn New  - = - = - = - = - ')

    if (localStorage.getItem('userType')?.toLowerCase() !== 'moderator') {
      setLoading2(true)
      let allDetails = null
      if (date.start && date.end) {
        allDetails = await dispatch(
          getCheckins({
            start: moment(date.start).format('YYYY-MM-DD'),
            end: moment(date.end).format('YYYY-MM-DD'),
            userType: 'employee',
          }),
        )
        console.log('- = - = - = - = - > allDetails < - = - = - = - = - = - = -', allDetails)
        updateData(allDetails)
      } else {
        allDetails = await dispatch(getCheckins({ userType: 'employee' }))
        console.log('- = - = - = - = - > allDetails < - = - = - = - = - = - = -', allDetails)
        // updateData(allDetails)
      }
      setLoading2(false)
      intRunChange(false)
    }
  }

  useEffect(() => {
    console.log('checkinnew')
  }, [])

  useEffect(() => {
    console.log('use-Effect data update', data)
    //
    // fetchCheckins()
  }, [data])

  useEffect(() => {
    // console.log('use-Effect getUserTimelineScreenshots')

    if (localStorage.getItem('token') && localStorage.getItem('userType') === 'employee') {
      // get all checkins of user from db
      // dispatch(getUserTimelineScreenshots())
    }
  }, [])

  useEffect(() => {
    // console.log("use-Effect on socket update");

    if (socket) {
      socket.on('checkinStatus', (data) => {
        removeCheckInDetails()
        stopStreamedVideo()
        // handleStopCapturing()
      })
    }
  }, [socket])

  const handleShiftEnd = () => {
    localStorage.removeItem('project')
    if (!localStorage.getItem('project') && !localStorage.getItem('projectCheckInId')) {
      setVisible(true)
    } else {
      //  swal('Project is Checked In', 'Please checkout the project first', 'warning')
      openRemarksModal()
    }
  }

  const openRemarksModal = () => {
    setRemarksModal(true)
  }
  const closeRemarksModal = async () => {
    await handleRemarks()
    stopStreamedVideo()
    setRemarksModal(false)
  }
  const handleRemarks = async (value) => {
    // add remarks and checkout
    setBtnLoading(true)
    await checkOut2({ remarks: value?.remarks || '' })
    checkOut()

    setRemarksModal(false)
    setBtnLoading(false)
  }

  const checkOut2 = async (data) => {
    localStorage.removeItem('project')
    // await sendOfflineScreenshots()
    const allDetails = await dispatch(
      projectCheckOut({
        checkIn: localStorage.getItem('projectCheckInId'),
        remarks: data?.remarks,
      }),
    )
    if (allDetails) {
      setProjectCheckInId('')
      setProjectId('')
    }
    localStorage.removeItem('projectCheckInId')
    stopStreamedVideo()
    setCurrentCheckIn(null)
    // handleStopCapturing()
    clearInterval(timer) // Clear the timer interval
    setTimer(null) // Reset the timer state
    setVideoEl(null) // Reset the video element state
  }

  function stopStreamedVideo() {
    if (videoEL) {
      const streamVar = videoEL.srcObject
      if (streamVar) {
        const tracks = streamVar.getTracks()

        tracks.forEach((track) => {
          track.stop()
        })
      }
      clearInterval(intervalState)
    }
  }

  const removeCheckInDetails = () => {
    localStorage.removeItem('project')
    localStorage.removeItem('projectCheckInId')
    setProjectCheckInId('')
    setCurrentCheckIn(null)
    setProjectId('')
  }

  // const sendOfflineScreenshots = () => {
  //   let offlineScreenshots = localStorage.getItem('offlineScreenshots')
  //     ? JSON.parse(localStorage.getItem('offlineScreenshots'))
  //     : []

  //   if (offlineScreenshots.length > 0) {
  //     // .format("YYYY-MM-DD--HH-MM")

  //     let formData = new FormData()
  //     offlineScreenshots?.map((data) => {
  //       const blob = dataURItoBlob(data.file)

  //       var file = new File([blob], `${data.date}.jpg`, {
  //         type: 'application/octet-stream',
  //       })
  //       formData.append('screenshot', file)
  //     })
  //     formData.append('checkinId', localStorage.getItem('checkinId'))
  //     addOfflineScreenshot(formData)
  //   }
  // }

  const startShiftCheckin = async () => {
    const zone = moment.tz.guess(true) // modification required
    try {
      setLoading(true)

      const allDetails = await dispatch(
        startTask(
          {
            latitude: 0,
            longitude: 0,
          },
          zone,
          userId,
        ),
      )

      console.log('startShiftCheckin in  CheckIn New', allDetails)
      setData(allDetails)
      // fetchCheckins()
      // console.error('sa ga ma pa ga ma pa ')
      setCheckinId(getCheckin())

      setLoading(false)
    } catch ({ message }) {
      console.log('we here')
    }
  }

  const checkOut = async () => {
    setCheckinHistory(false)
    setLoading(true)
    const allDetails = await dispatch(endTask(false, userId))
    setData(allDetails)
    setLoading(false)
    localStorage.removeItem('checkinId')
    setCheckinId(null)
    setCheckinId(null)
  }
  const isShiftOn = () => {
    if (!Array.isArray(checkins)) {
      console.error('checkins is not an array')
      return false // or handle this case as per your requirements
    }

    let index = checkins?.findIndex((obj) => obj.user === userId && !obj.end)
    if (index >= 0 && !localStorage.getItem('checkinId')) {
      localStorage.setItem('checkinId', checkins[index]?._id)
      console.error('sa ga ma pa ga ma pa ')

      setCheckinId(checkins[index]?._id)
    }
    console.log('index === -1', index === -1)
    return index === -1
  }

  const [visible, setVisible] = useState(false)
  return (
    <Layout active='checkin'>
      <div>
        <Modal
          title={t('add-remarks')}
          open={remarksModal}
          footer={false}
          onCancel={closeRemarksModal}
          width={'600px'}
        >
          <Form requiredMark={false} layout='vertical' form={form} onFinish={handleRemarks}>
            <Row gutter={[20, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t('remarks')}
                  name={'remarks'}
                  rules={[{ required: true, message: 'Please input remarks!' }]}
                >
                  <Input.TextArea rows={4} style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button htmlType='submit' loading={btnLoading} type='primary'>
                {t('add-remarks')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>

      <div className='checkin-btn'>
        <div style={{ position: 'absolute' }}>
          {isShiftOn() ? (
            <Button
              type='primary'
              loading={loading}
              onClick={async () => {
                startShiftCheckin()
              }}
            >
              {t('shift-start')}
            </Button>
          ) : (
            <Button
              type='primary'
              loading={loading}
              // disabled={getCheckinProject()}
              onClick={async () => {
                handleShiftEnd()
              }}
            >
              {t('shift-end')}
            </Button>
          )}
        </div>
        {checkinId && (
          <div style={{ marginRight: '110px', display: 'flex', flexDirection: 'row', gap: 10 }}>
            <Select
              onChange={(value, option) => {
                setSelectedProject(option.label)
                localStorage.setItem('project', value)
              }}
              // disabled={getCheckinProject() }
              defaultValue={defaultValue}
              value={selectedProject ? selectedProject : defaultValue}
              style={{ width: '250px' }}
              options={projects?.map((project) => ({
                value: project._id,
                label: project.title,
                disabled: project.title === selectedProject,
              }))}
            />
            {selectedProject && (
              <ProjectCheckIn
                projectTitle={projectTitle}
                setProjectTitle={setProjectTitle}
                selectedProjectName={selectedProject}
                intervalState={intervalState}
                // isShiftOn={isShiftOn()}
                currentCheckIn={currentCheckIn}
                videoEL={videoEL}
                setVideoEl={setVideoEl}
                setIntervalState={setIntervalState}
                TrackState={TrackState}
                setTrackState={setTrackState}
                projects={projects}
                condition={condition}
                setCondition={setCondition}
                updateData={updateData}
              />
            )}
          </div>
        )}
      </div>

      <div className='toggle-checkin-history-btn-container'>
        {data &&
          (data?.length > 0 || data?.checkins?.length > 0 || data?.[0]?.checkins ? (
            // {
            checkinHistory ? (
              <div className='toggle-checkin-history-btn' onClick={() => setCheckinHistory(false)}>
                {t('Hide History')} <IoChevronUp style={{ fontSize: '1.3rem', marginTop: '5px' }} />
              </div>
            ) : (
              <div className='toggle-checkin-history-btn' onClick={() => setCheckinHistory(true)}>
                {t('Show History')}{' '}
                <IoChevronDownSharp style={{ fontSize: '1.3rem', marginTop: '5px' }} />
              </div>
            )
          ) : (
            // }
            false
          ))}
      </div>
      <div className='checkin-head'>
        <Modal
          title='Check Out'
          open={visible}
          onOk={() => {
            checkOut()
            setVisible(false)
          }}
          onCancel={() => {
            setVisible(false)
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: '400',
              color: 'var(--text)',
            }}
          >
            Are you sure you want to check out?
          </p>
        </Modal>
      </div>

      {checkinHistory ? <CheckInHistoryComponet loading={loading} /> : false}
    </Layout>
  )
}

export default Checkins
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   )
// }
