import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Input,
  Modal,
  Form,
  Row,
  Select,
  Table,
} from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

import swal from 'sweetalert'
import InfinityLoader from '../components/InfinityLoader'
import { getTimeInHoursMins } from '../functions/timeFormatter'
import Layout from '../layout/Layout'
import {
  _projectCheckIn,
  addOfflineScreenshot,
  addScreenshot,
  endTask,
  getAllEmployeeProjects,
  projectCheckOut,
  getCheckins,
  getUserTimelineScreenshots,
  startTask,
} from '../redux'
import dataURItoBlob from '../service/dataURItoBlob'
import '../styles/pages/checkins.css'
import ProjectCheckIn from './ProjectCheckIn'
let intRun = false
const intRunChange = (val) => {
  intRun = val
  if (val == false) {
    // clearinterval
  }
}
const Checkins = ({
  intervalState,
  videoEL,
  TrackState,
  setIntervalState,
  setVideoEl,
  setTrackState,
}) => {
  // const projects = useSelector(state => state.projects.projects);
  // const projects = useSelector(state => state.projects);
  const [projectId, setProjectId] = useState(localStorage.getItem('project'))
  const [projectCheckInId, setProjectCheckInId] = useState(localStorage.getItem('projectCheckInId'))
  const [forceRender, setForceRender] = useState(false)
  const [checkinTime, setCheckInTime] = useState('')

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [date, setDate] = useState({
    start: null,
    end: null,
  })
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const { projects } = useSelector((state) => state.project)
  const userId = localStorage.getItem('userId')
  const checkins = useSelector((state) => state.shiftCheckin.checkins)
  const [currentCheckIn, setCurrentCheckIn] = useState(null)
  const { socket } = useSelector((state) => state.socket)
  const [timer, setTimer] = useState(null)
  const [form] = Form.useForm() // Initialize
  const [btnLoading, setBtnLoading] = useState(false)
  const [remarksModal, setRemarksModal] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(getAllEmployeeProjects())
    }
  }, [dispatch])

  useEffect(() => {
    fetchCheckins()
  }, [date])

  useEffect(() => {
    console.log('useEffect getUserTimelineScreenshots')
    if (localStorage.getItem('token') && localStorage.getItem('userType') === 'employee') {
      // get all checkins of user from db
      // dispatch(getUserTimelineScreenshots())
    }
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on('checkinStatus', (data) => {
        removeCheckInDetails()
        stopStreamedVideo()
        handleStopCapturing()
      })
    }
  }, [socket])

  const handleShiftEnd = () => {
    localStorage.removeItem('project')
    if (!localStorage.getItem('project') && !localStorage.getItem('projectCheckInId')) {
      setVisible(true)
    } else {
      //  swal('Project is Checked In', 'Please checkout the project first', 'warning')
      openRemarksModal()
    }
  }

  const openRemarksModal = () => {
    setRemarksModal(true)
  }
  const closeRemarksModal = async () => {
    await handleRemarks()
    stopStreamedVideo()
    setRemarksModal(false)
  }
  const handleRemarks = async (value) => {
    // add remarks and checkout
    setBtnLoading(true)
    await checkOut2({ remarks: value?.remarks || '' })
    checkOut()

    setRemarksModal(false)
    setBtnLoading(false)
  }

  const checkOut2 = async (data) => {
    localStorage.removeItem('project')
    await sendOfflineScreenshots()
    const allDetails = await dispatch(
      projectCheckOut({
        checkIn: localStorage.getItem('projectCheckInId'),
        remarks: data?.remarks,
      }),
    )
    if (allDetails) {
      setProjectCheckInId('')
      setProjectId('')
    }
    localStorage.removeItem('projectCheckInId')
    stopStreamedVideo()
    setCurrentCheckIn(null)
    handleStopCapturing()
    clearInterval(timer) // Clear the timer interval
    setTimer(null) // Reset the timer state
    setVideoEl(null) // Reset the video element state
  }

  function stopStreamedVideo() {
    if (videoEL) {
      const streamVar = videoEL.srcObject
      if (streamVar) {
        const tracks = streamVar.getTracks()

        tracks.forEach((track) => {
          track.stop()
        })
      }
      clearInterval(intervalState)
    }
  }

  const removeCheckInDetails = () => {
    localStorage.removeItem('project')
    localStorage.removeItem('projectCheckInId')
    setProjectCheckInId('')
    setCurrentCheckIn(null)
    setProjectId('')
  }

  const onDateChange = (date, dateString) => {
    setDate({
      start: dateString[0],
      end: dateString[1],
    })
  }

  const sendOfflineScreenshots = () => {
    let offlineScreenshots = localStorage.getItem('offlineScreenshots')
      ? JSON.parse(localStorage.getItem('offlineScreenshots'))
      : []

    if (offlineScreenshots.length > 0) {
      // .format("YYYY-MM-DD--HH-MM")

      let formData = new FormData()
      offlineScreenshots?.map((data) => {
        const blob = dataURItoBlob(data.file)

        var file = new File([blob], `${data.date}.jpg`, {
          type: 'application/octet-stream',
        })
        formData.append('screenshot', file)
      })
      formData.append('checkinId', localStorage.getItem('checkinId'))
      addOfflineScreenshot(formData)
    }
  }

  const handleChnage = (e) => {
    e === 'today'
      ? setDate({
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'yesterday'
      ? setDate({
          start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        })
      : e === 'last7Days'
      ? setDate({
          start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'last30Days'
      ? setDate({
          start: moment().subtract(30, 'day').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'thisMonth'
      ? setDate({
          start: moment().startOf('month').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : setDate({
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
  }

  const fetchCheckins = async () => {
    if (localStorage.getItem('userType')?.toLowerCase() !== 'moderator') {
      setLoading2(true)
      let allDetails = null
      if (date.start && date.end) {
        allDetails = await dispatch(
          getCheckins({
            start: moment(date.start).format('YYYY-MM-DD'),
            end: moment(date.end).format('YYYY-MM-DD'),
            userType: 'employee',
          }),
        )
        setData(allDetails)
      } else {
        allDetails = await dispatch(getCheckins({ userType: 'employee' }))
        setData(allDetails)
      }
      setLoading2(false)
      intRunChange(false)
    }
  }

  const startShiftCheckin = async () => {
    const zone = moment.tz.guess(true) // modification required
    try {
      setLoading(true)

      const allDetails = await dispatch(
        startTask(
          {
            latitude: 0,
            longitude: 0,
          },
          zone,
          userId,
        ),
      )

      // console.log(allDetails)
      setData(allDetails)
      fetchCheckins()

      setLoading(false)
    } catch ({ message }) {
      console.log('we here')
    }
  }
  const checkOut = async () => {
    setLoading(true)
    const allDetails = await dispatch(endTask(false, userId))
    setData(allDetails)
    setLoading(false)
    localStorage.removeItem('checkinId')
  }
  const isShiftOn = () => {
    if (!Array.isArray(checkins)) {
      console.error('checkins is not an array')
      return false // or handle this case as per your requirements
    }

    let index = checkins?.findIndex((obj) => obj.user === userId && !obj.end)
    if (index >= 0 && !localStorage.getItem('checkinId')) {
      localStorage.setItem('checkinId', checkins[index]?._id)
    }
    return index === -1
  }

  const handleStartCapturing = () => {
    if (window?.electronAPI?.startCapturing) window.electronAPI.startCapturing()
  }

  const handleStopCapturing = () => {
    if (window?.electronAPI?.stopCapturing) window.electronAPI.stopCapturing()
  }

  const [visible, setVisible] = useState(false)

  return (
    <Layout active='checkin'>
      <div>
        <Modal
          title={t('add-remarks')}
          open={remarksModal}
          footer={false}
          onCancel={closeRemarksModal}
          width={'600px'}
        >
          <Form requiredMark={false} layout='vertical' form={form} onFinish={handleRemarks}>
            <Row gutter={[20, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t('remarks')}
                  name={'remarks'}
                  rules={[{ required: true, message: 'Please input remarks!' }]}
                >
                  <Input.TextArea rows={4} style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button htmlType='submit' loading={btnLoading} type='primary'>
                {t('add-remarks')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div className='checkin-btn'>
        <div style={{ position: 'absolute' }}>
          {isShiftOn() ? (
            <Button
              type='primary'
              loading={loading}
              onClick={async () => {
                startShiftCheckin()
              }}
            >
              {t('shift-start')}
            </Button>
          ) : (
            <Button type='primary' loading={loading} onClick={handleShiftEnd}>
              {t('shift-end')}
            </Button>
          )}
        </div>
        <div style={{ marginRight: '110px', display: 'flex', flexDirection: 'row', gap: 10 }}>
          <Select
            onChange={(value, option) => {
              setSelectedProject(option.label)
              localStorage.setItem('project', value)
            }}
            defaultValue='Projects'
            style={{ width: '250px' }}
            options={projects?.map((project) => ({
              value: project._id,
              label: project.title,
              disabled: project.title === selectedProject,
            }))}
          />
          {selectedProject && (
            <ProjectCheckIn
              selectedProject={selectedProject}
              intervalState={intervalState}
              // isShiftOn={isShiftOn()}
              currentCheckIn={currentCheckIn}
              videoEL={videoEL}
              setVideoEl={setVideoEl}
              setIntervalState={setIntervalState}
              TrackState={TrackState}
              setTrackState={setTrackState}
              projects={projects}
            />
          )}
        </div>
      </div>
      <div className='checkin-head'>
        <Modal
          title='Check Out'
          open={visible}
          onOk={() => {
            checkOut()

            setVisible(false)
          }}
          onCancel={() => {
            setVisible(false)
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: '400',
              color: 'var(--text)',
            }}
          >
            Are you sure you want to check out?
          </p>
        </Modal>
        <h2>{t('checkin-history')}</h2>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Select
            onChange={(e) => handleChnage(e)}
            defaultValue='today'
            style={{
              width: '250px',
            }}
            options={[
              {
                value: 'today',
                label: 'Today',
              },
              {
                value: 'yesterday',
                label: 'Yesterday',
              },
              {
                value: 'last7Days',
                label: 'Last 7 Days',
              },
              {
                value: 'last30Days',
                label: 'Last 30 Days',
              },
              // {
              //   value: 'thisMonth',
              //   label: 'This Month',
              // },
              {
                value: 'lastMonth',
                label: 'Last Month',
              },
            ]}
          />
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={onDateChange}
            disabledDate={(d) => {
              return d?.isAfter(moment())
              // return moment(d).day() === 0 || d?.isAfter(moment());
            }}
          />
        </div>
      </div>
      {loading2 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <InfinityLoader />
        </div>
      ) : (
        <div className='checkin-history'>
          {data ? (
            data?.length > 0 ? (
              <div className='checkin-history-c1'>
                {data.map((checkin, index) => {
                  return (
                    !loading2 &&
                    !loading && (
                      <SnapComponent
                        key={index}
                        checkin={checkin}
                        date={date}
                        checkins={data?.checkins}
                      />
                    )
                  )
                })}
              </div>
            ) : (
              <div className='empty'>
                <Empty description={t('no-checkins-today')} />
              </div>
            )
          ) : (
            <div className='empty'>
              <Empty description={t('no-checkins-found')} />
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}

const SnapComponent = ({ date, checkins, checkin }) => {
  const [show, setShow] = useState(false)
  // const [data, setData] = useState(null)
  // const [totalHours, setTotalHours] = useState(0)
  const btnShow = (date) => {
    let details = []
    let time = 0
    let flag = {
      flag: false,
      date: '',
    }
    checkin?.projectCheckIns?.map((projectCheckIn) => {
      if (moment(checkin.date).format('YYYY-MM-DD') === date) {
        details.push(checkin)
        if (checkin.time.end !== 'Ongoing') {
          time = time + checkin.hours
        } else {
          let ongoingTime = 0
          ongoingTime = new Date() - new Date(checkin.time.start)
          ongoingTime = ongoingTime / 1000 / 60 / 60
          time = time + ongoingTime
        }
      }
    })
    setShow(!show)
  }

  const columns = [
    {
      title: 'Project',
      dataIndex: 'project',
      render: (text, record) => {
        // _id is project
        return <p>{record?.project?.title}</p>
      },
    },
    {
      title: 'Number of Checkins',
      dataIndex: 'checkins',
      render: (text, record) => {
        return <p>{record?.checkins?.length}</p>
      },
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (text, record) => {
        const now = new Date() // Current date and time

        let sorted = record?.checkins
          ?.slice()
          ?.sort((val1, val2) => new Date(val1?.start) - new Date(val2?.start))

        let totalHours = 0

        for (const shift of sorted) {
          const startTime = new Date(shift.start)
          const endTime = shift?.end === undefined ? now : new Date(shift?.end)

          const duration = (endTime - startTime) / (1000 * 60 * 60) // Convert milliseconds to hours
          totalHours += duration
        }

        return <p>{`${getTimeInHoursMins(totalHours)}`}</p>
      },
    },
  ]
  return (
    <>
      <div className='screenshot-section'>
        <div
          style={{
            width: '100%',
            border: '1px solid var(--borderLight',
            padding: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              width: '100%',
              margin: '0px 0px 10px 0px',
            }}
            onClick={() => btnShow(checkin?.checkIn?.start)}
          >
            <span
              style={{
                color: 'var(--text)',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {moment(checkin?.checkIn?.start).tz('Asia/Karachi').format('dddd, DD-MMM-YYYY')}
            </span>
            {show ? (
              <IoIosArrowDropup
                style={{
                  color: 'var(--text)',
                  fontSize: '2rem',
                  marginRight: '10px',
                }}
              />
            ) : (
              <IoIosArrowDropdown
                style={{
                  color: 'var(--text)',
                  fontSize: '2rem',
                  marginRight: '10px',
                }}
              />
            )}
          </div>
          {show && (
            <>
              <Table
                showHeader={true}
                sticky
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <Row
                      gutter={[30, 15]}
                      style={{
                        border: '1px solid var(--borderLight)',
                        borderRadius: '5px',
                        padding: '1rem',
                        margin: '1rem',
                        boxShadow: 'var(--shadow)',
                      }}
                    >
                      <Col span={24}>
                        <h2
                          style={{
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            color: 'var(--text)',
                            margin: '0',
                          }}
                        >
                          {t('checkin-details')}
                        </h2>
                        <Divider
                          style={{
                            margin: '0',
                            borderColor: 'var(--text)',
                          }}
                        />
                      </Col>

                      <Col span={24}>
                        <Row gutter={[15, 15]} justify='space-around'>
                          {checkin?.projectCheckIns
                            ?.find((val) => val?.project?._id === record?.project?._id)
                            ?.checkins?.map((projectCheckIn, index) => {
                              return (
                                <Col xs={24} md={10} lg={6} key={index}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                      padding: '0.5rem',
                                      border: '1px solid var(--borderLight)',
                                      borderRadius: '5px',
                                      boxShadow: 'var(--shadow)',
                                      minWidth: '170px',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {index + 1}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '0.8rem',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {t('start')} :{' '}
                                      {moment(projectCheckIn?.start)
                                        .tz('Asia/Karachi')
                                        .format('hh:mm a')}{' '}
                                      (local)
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '0.8rem',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {t('end')} :{' '}
                                      {projectCheckIn?.end
                                        ? moment(projectCheckIn?.end)
                                            .tz('Asia/Karachi')
                                            .format('hh:mm a')
                                        : 'Ongoing'}
                                      (local)
                                    </span>
                                  </div>
                                </Col>
                              )
                            })}
                        </Row>
                      </Col>
                    </Row>
                  ),
                }}
                dataSource={checkin?.projectCheckIns?.map((value, index) => ({
                  ...value,
                  key: index,
                }))}
                columns={columns}
                // scroll={{ x: 700 }}
                pagination={false}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Checkins
