import { useState, useEffect } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Input,
  Modal,
  Form,
  Row,
  Select,
  Table,
} from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import InfinityLoader from '../components/InfinityLoader'
import { getTimeInHoursMins } from '../functions/timeFormatter'
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getCheckins } from '../redux'

function CheckInHistoryComponet({ loading }) {
  const [date, setDate] = useState({
    start: null,
    end: null,
  })
  const [loading2, setLoading2] = useState(false)
  let intRun = false
  // const [loading, setLoading] = useState(false)

  const [data, setData] = useState()
  // console.log(checkinHistory, loading2, loading, data)
  const dispatch = useDispatch()

  const handleChnage = (e) => {
    e === 'today'
      ? setDate({
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'yesterday'
      ? setDate({
          start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        })
      : e === 'last7Days'
      ? setDate({
          start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'last30Days'
      ? setDate({
          start: moment().subtract(30, 'day').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'thisMonth'
      ? setDate({
          start: moment().startOf('month').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : setDate({
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
  }

  const onDateChange = (date, dateString) => {
    setDate({
      start: dateString[0],
      end: dateString[1],
    })
  }
  const intRunChange = (val) => {
    intRun = val
    if (val == false) {
      // clearinterval
    }
  }
  const fetchCheckins = async () => {
    // console.log('fetch check In Start in CheckInHistoryComponet  - = - = - = - = - ')

    if (localStorage.getItem('userType')?.toLowerCase() !== 'moderator') {
      setLoading2(true)
      let allDetails = null
      if (date.start && date.end) {
        allDetails = await dispatch(
          getCheckins({
            start: moment(date.start).format('YYYY-MM-DD'),
            end: moment(date.end).format('YYYY-MM-DD'),
            userType: 'employee',
          }),
        )
        setData(allDetails)
      } else {
        allDetails = await dispatch(getCheckins({ userType: 'employee' }))
        setData(allDetails)
      }
      setLoading2(false)
      intRunChange(false)
    }
  }

  useEffect(() => {
    // console.log("use-Effect Get fetch checkin on date update",date);
    fetchCheckins()
  }, [date])

  return (
    <div>
      <div className='checkin-head'>
        {/* { 
          checkinHistory &&
          <> */}
        <h2>{t('checkin-history')}</h2>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Select
            onChange={(e) => handleChnage(e)}
            defaultValue='today'
            style={{
              width: '250px',
            }}
            options={[
              {
                value: 'today',
                label: 'Today',
              },
              {
                value: 'yesterday',
                label: 'Yesterday',
              },
              {
                value: 'last7Days',
                label: 'Last 7 Days',
              },
              {
                value: 'last30Days',
                label: 'Last 30 Days',
              },
              // {
              //   value: 'thisMonth',
              //   label: 'This Month',
              // },
              {
                value: 'lastMonth',
                label: 'Last Month',
              },
            ]}
          />
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={onDateChange}
            disabledDate={(d) => {
              return d?.isAfter(moment())
              // return moment(d).day() === 0 || d?.isAfter(moment());
            }}
          />
        </div>
        {/* </>
        } */}
      </div>

      {/* { checkinHistory && 
      <> */}
      {loading2 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <InfinityLoader />
        </div>
      ) : (
        <div className='checkin-history'>
          {data ? (
            data?.length > 0 ? (
              <div className='checkin-history-c1'>
                {data?.map((checkin, index) => {
                  return (
                    !loading2 &&
                    !loading && (
                      <SnapComponent
                        key={index}
                        checkin={checkin}
                        date={date}
                        checkins={data?.checkins}
                      />
                    )
                  )
                })}
              </div>
            ) : (
              <div className='empty'>
                <Empty description={t('no-checkins-today')} />
              </div>
            )
          ) : (
            <div className='empty'>
              <Empty description={t('no-checkins-found')} />
            </div>
          )}
        </div>
      )}
      {/* </>
      } */}
    </div>
  )
}
const SnapComponent = ({ date, checkins, checkin }) => {
  const [show, setShow] = useState(false)
  // const [data, setData] = useState(null)
  // const [totalHours, setTotalHours] = useState(0)
  const btnShow = (date) => {
    let details = []
    let time = 0
    let flag = {
      flag: false,
      date: '',
    }
    checkin?.projectCheckIns?.map((projectCheckIn) => {
      if (moment(checkin.date).format('YYYY-MM-DD') === date) {
        details.push(checkin)
        if (checkin.time.end !== 'Ongoing') {
          time = time + checkin.hours
        } else {
          let ongoingTime = 0
          ongoingTime = new Date() - new Date(checkin.time.start)
          ongoingTime = ongoingTime / 1000 / 60 / 60
          time = time + ongoingTime
        }
      }
    })
    setShow(!show)
  }

  const columns = [
    {
      title: 'Project',
      dataIndex: 'project',
      render: (text, record) => {
        // _id is project
        return <p>{record?.project?.title}</p>
      },
    },
    {
      title: 'Number of Checkins',
      dataIndex: 'checkins',
      render: (text, record) => {
        return <p>{record?.checkins?.length}</p>
      },
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (text, record) => {
        const now = new Date() // Current date and time

        let sorted = record?.checkins
          ?.slice()
          ?.sort((val1, val2) => new Date(val1?.start) - new Date(val2?.start))

        let totalHours = 0

        for (const shift of sorted) {
          const startTime = new Date(shift.start)
          const endTime = shift?.end === undefined ? now : new Date(shift?.end)

          const duration = (endTime - startTime) / (1000 * 60 * 60) // Convert milliseconds to hours
          totalHours += duration
        }

        return <p>{`${getTimeInHoursMins(totalHours)}`}</p>
      },
    },
  ]
  return (
    <>
      <div className='screenshot-section'>
        <div
          style={{
            width: '100%',
            border: '1px solid var(--borderLight',
            padding: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              width: '100%',
              margin: '0px 0px 10px 0px',
            }}
            onClick={() => btnShow(checkin?.checkIn?.start)}
          >
            <span
              style={{
                color: 'var(--text)',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {moment(checkin?.checkIn?.start).tz('Asia/Karachi').format('dddd, DD-MMM-YYYY')}
            </span>
            {show ? (
              <IoIosArrowDropup
                style={{
                  color: 'var(--text)',
                  fontSize: '2rem',
                  marginRight: '10px',
                }}
              />
            ) : (
              <IoIosArrowDropdown
                style={{
                  color: 'var(--text)',
                  fontSize: '2rem',
                  marginRight: '10px',
                }}
              />
            )}
          </div>
          {show && (
            <>
              <Table
                showHeader={true}
                sticky
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <Row
                      gutter={[30, 15]}
                      style={{
                        border: '1px solid var(--borderLight)',
                        borderRadius: '5px',
                        padding: '1rem',
                        margin: '1rem',
                        boxShadow: 'var(--shadow)',
                      }}
                    >
                      <Col span={24}>
                        <h2
                          style={{
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            color: 'var(--text)',
                            margin: '0',
                          }}
                        >
                          {t('checkin-details')}
                        </h2>
                        <Divider
                          style={{
                            margin: '0',
                            borderColor: 'var(--text)',
                          }}
                        />
                      </Col>

                      <Col span={24}>
                        <Row gutter={[15, 15]} justify='space-around'>
                          {checkin?.projectCheckIns
                            ?.find((val) => val?.project?._id === record?.project?._id)
                            ?.checkins?.map((projectCheckIn, index) => {
                              return (
                                <Col xs={24} md={10} lg={6} key={index}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                      padding: '0.5rem',
                                      border: '1px solid var(--borderLight)',
                                      borderRadius: '5px',
                                      boxShadow: 'var(--shadow)',
                                      minWidth: '170px',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {index + 1}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '0.8rem',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {t('start')} :{' '}
                                      {moment(projectCheckIn?.start)
                                        .tz('Asia/Karachi')
                                        .format('hh:mm a')}{' '}
                                      (local)
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '0.8rem',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {t('end')} :{' '}
                                      {projectCheckIn?.end
                                        ? moment(projectCheckIn?.end)
                                            .tz('Asia/Karachi')
                                            .format('hh:mm a')
                                        : 'Ongoing'}
                                      (local)
                                    </span>
                                  </div>
                                </Col>
                              )
                            })}
                        </Row>
                      </Col>
                    </Row>
                  ),
                }}
                dataSource={checkin?.projectCheckIns?.map((value, index) => ({
                  ...value,
                  key: index,
                }))}
                columns={columns}
                // scroll={{ x: 700 }}
                pagination={false}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CheckInHistoryComponet
