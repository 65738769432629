export const getCheckin = () => {
  return localStorage.getItem('checkinId')
}
export const getCheckinProject = () => {
  return localStorage.getItem('projectCheckInId')
}

export const getSelectedProject = () => {
  return localStorage.getItem('project')
}
