// // import { Button } from 'antd'
// import moment from 'moment'
// import React, { useEffect, useState } from 'react'

// import { useDispatch, useSelector } from 'react-redux'
// import swal from 'sweetalert'
// import {
//   _projectCheckIn,
//   addOfflineScreenshot,
//   addScreenshot,
//   projectCheckOut,
//   startTask,
//   getCheckins,
//   setShiftCheckins,
// } from '../redux'
// import dataURItoBlob from '../service/dataURItoBlob'
// import { Button, Col, Form, Input, Modal, Row, Spin, notification } from 'antd'
// import { useTranslation } from 'react-i18next'
// import { getCheckinProject, getSelectedProject } from '../functions/globals'
// import { base_url } from '../config/constants'
// // import StringTimer from '../components/StringTimer';

// let intRun = false
// const intRunChange = (val) => {
//   intRun = val
//   if (val == false) {
//     // clearinterval
//   }
// }

// const ProjectCheckIn = ({
//   videoEL,
//   isShiftOn,
//   setVideoEl,
//   intervalState,
//   selectedProject,
//   projects,
//   TrackState,
//   projectTitle,
//   setProjectTitle,
//   // condition,
//   // setCondition
//   // updateData
// }) => {
//   const { t } = useTranslation()
//   const dispatch = useDispatch()
//   const [currentCheckIn, setCurrentCheckIn] = useState(() => {
//     // Retrieve currentCheckIn from localStorage if available
//     const storedCheckInStart = localStorage.getItem('currentCheckInStartTime')

//     return storedCheckInStart ? new Date(storedCheckInStart) : null
//   })
//   const [runningProject, setRunningProject] = useState(null)
//   const [projectId, setProjectId] = useState(localStorage.getItem('project'))
//   const [timer, setTimer] = useState(null)
//   const [screenShotstimer, setScreenShotstimer] = useState(null)
//   const [projectCheckInId, setProjectCheckInId] = useState(localStorage.getItem('projectCheckInId'))
//   const [remarksModal, setRemarksModal] = useState(false)
//   const [btnLoading, setBtnLoading] = useState(false)
//   const [condition, setCondition] = useState(false)
//   const userId = localStorage.getItem('userId')
//   const [startProjectLoading, setStartProjectLoading] = useState(false)
//   const [shareScreenInProgress, setShareScreenInProgress] = useState(false)
//   const projectCheckInTime = useSelector((state) => state.shiftCheckin.projectCheckInTime)
//   const currentCheckInStartTime = useSelector((state) => state.shiftCheckin.currentCheckInStartTime)
//   const projectCurrentCheckInId = useSelector((state) => state.shiftCheckin.projectCheckInId)
//   const isMediaActive = useSelector((state) => state.shiftCheckin.isMediaActive)
//   // const [isMediaActive, setIsMediaActive] = useState(false)

//   // const [projectTitle, setProjectTitle] = useState(false)
//   const [date, setDate] = useState({
//     start: null,
//     end: null,
//   })
//   const [form] = Form.useForm() // Initialize form

//   const [timeString, setTimeString] = useState('')
//   // console.log("🚀 ~ timeString:", timeString)
//   // dispatch(setShiftCheckins(localStorage.getItem('projectCheckInId')))

//   //   functions

//   function stopStreamedVideo() {
//     if (videoEL) {
//       const streamVar = videoEL.srcObject
//       if (streamVar) {
//         const tracks = streamVar.getTracks()

//         tracks.forEach((track) => {
//           track.stop()
//         })
//       }
//       clearInterval(intervalState)
//     }
//   }

//   // useEffect(() => {

//   //   console.log('use-Effect on intervalState, TrackState update');

//   //   if (intervalState && TrackState && localStorage.getItem('checkinId')) {
//   //     TrackState.onended = async () => {
//   //       clearInterval(intervalState)
//   //       checkOut()
//   //     }
//   //   }
//   // }, [intervalState, TrackState])

//   const handleStopCapturing = () => {
//     if (window?.electronAPI?.stopCapturing) window.electronAPI.stopCapturing()
//   }

//   window.addEventListener('beforeunload', () => {
//     localStorage.removeItem('projectCheckInId')
//   })

//   // abhi isko dekhna he
//   // useEffect(() => {

//   //   console.log('use-Effect on currentCheckIn Update',projectCheckInId)

//   //   if (!projectCheckInId) {
//   //     setCurrentCheckIn(null)
//   //     localStorage.removeItem('currentCheckInStartTime')
//   //     console.log('aj mai uppar asman niche')
//   //     return () => {
//   //       clearInterval(newTimer) // Clear the new timer
//   //     }
//   //   }
//   //   // if (getCheckinProject()) {
//   //     var startDate = new Date(currentCheckIn).getTime()
//   //     var newTimer = setInterval(() => {
//   //       console.log("ys chala in use effect")
//   //       timerFunction(startDate)
//   //     }, 1000)
//   //     setTimer(newTimer) // Save the new timer
//   //     return () => {
//   //       clearInterval(newTimer) // Clear the new timer
//   //     }
//   //   // }

//   // }, [currentCheckIn , condition ])

//   async function continueCaptureIng() {
//     console.log('🚀 ~ continueCaptureIng ~ isMediaActive:', isMediaActive)
//     if (isMediaActive?.active) {
//       let track = isMediaActive.getVideoTracks()[0]
//       let { width, height } = track.getSettings()
//       let videoELReference = document.createElement('video')
//       videoELReference.srcObject = isMediaActive
//       videoELReference.play()
//       setVideoEl(videoELReference)
//       captureImage({
//         videoELReference,
//         width,
//         height,
//       })
//     }
//   }

//   useEffect(() => {
//     // console.log("useEffect - - - - - > > > ",projectCheckInTime)

//     if (getCheckinProject()) {
//       if (!projectCheckInId) {
//         // console.log('projectCheckInId',projectCheckInId)
//         setProjectCheckInId(getCheckinProject())
//       }
//       var startDate = new Date(currentCheckIn).getTime()
//       var newTimer = setInterval(async () => {
//         // console.log("aj mai uppar asman niche",startDate)
//         timerFunction(currentCheckInStartTime)
//         // alooLeloo()
//       }, 1000)

//       setTimer(newTimer) // Save the new timer
//       return () => {
//         clearInterval(newTimer) // Clear the new timer
//       }
//     }
//     if (!getCheckinProject()) {
//       setCurrentCheckIn(null)
//       localStorage.removeItem('currentCheckInStartTime')
//     }
//   }, [currentCheckIn, condition, projectCheckInTime])

//   // useEffect(() => {
//   //   if (isMediaActive?.active) {
//   //     console.log('🚀 ~ useEffect ~ isMediaActive?.active:', isMediaActive?.active)
//   //     var newTimer = setInterval(async () => {
//   //       await continueCaptureIng()
//   //     }, 5000)
//   //     setScreenShotstimer(newTimer)
//   //   }
//   // }, [isMediaActive])

//   useEffect(() => {
//     let newTimer;

//     if (isMediaActive?.active) {
//       console.log('🚀 ~ useEffect ~ isMediaActive?.active:', isMediaActive?.active);

//       newTimer = setInterval(async () => {
//         await continueCaptureIng();
//       }, 10000);

//       setScreenShotstimer(newTimer);
//     }
//     return () => {
//       if (newTimer) {
//         clearInterval(newTimer);
//       }
//     };
//   }, [isMediaActive?.active]);

//   const openRemarksModal = () => {
//     // console.log('clicked on openremark model')
//     setRemarksModal(true)
//   }
//   const closeRemarksModal = async () => {
//     await handleRemarks()
//     stopStreamedVideo()
//     setRemarksModal(false)
//   }
//   const handleRemarks = async (value) => {
//     // add remarks and checkout
//     setBtnLoading(true)
//     await checkOut({ remarks: value?.remarks || '' })
//     setRemarksModal(false)
//     setBtnLoading(false)
//   }

//   const checkOut = async (data) => {
//     localStorage.removeItem('project')
//     // console.log('checkOut')
//     // await sendOfflineScreenshots()
//     const allDetails = await dispatch(
//       projectCheckOut({
//         checkIn: projectCheckInId,
//         remarks: data?.remarks,
//       }),
//     )
//     if (allDetails) {
//       setProjectCheckInId('')
//       setProjectId('')
//       localStorage.removeItem('projectCheckInId')
//     }
//     stopStreamedVideo()
//     setCurrentCheckIn(null)
//     handleStopCapturing()
//     clearInterval(timer)
//     setTimer(null)
//     setVideoEl(null)
//     dispatch({
//       type: 'CURRENT_CHECK_IN_TME',
//       payload: null,
//     })
//     dispatch({
//       type: 'SET_PROJECT_CHECKIN_TIME',
//       payload: '',
//     })
//     dispatch({
//       type: 'SET_PROJECT_CHECKIN_ID',
//       payload: null,
//     })
//     setProjectCheckInId(null)
//     setProjectTitle(false)
//     setCondition(!condition)
//   }

//   const fetchCheckins = async () => {
//     if (localStorage.getItem('userType')?.toLowerCase() !== 'moderator') {
//       let allDetails = null
//       if (date.start && date.end) {
//         allDetails = await dispatch(
//           getCheckins({
//             start: moment(date.start).format('YYYY-MM-DD'),
//             end: moment(date.end).format('YYYY-MM-DD'),
//             userType: 'employee',
//           }),
//         )
//         // updateData(allDetails)
//       } else {
//         allDetails = await dispatch(getCheckins({ userType: 'employee' }))
//         console.log('at 235 projectcheckin', allDetails)
//         // updateData(allDetails)
//       }
//       intRunChange(false)
//     }
//   }

//   // start shift function
//   const startShiftCheckin = async () => {
//     // modification required
//     setTimer(null)
//     try {
//       setStartProjectLoading(true)
//       const zone = moment.tz.guess(true)
//       const allDetails = dispatch(
//         startTask(
//           {
//             latitude: 0,
//             longitude: 0,
//           },
//           zone,
//           userId,
//         ),
//       )
//       console.log('allDetails in startShiftCheckin', allDetails)
//       // updateData(allDetails)

//       fetchCheckins()
//       setStartProjectLoading(false)
//     } catch ({ message }) {
//       console.log(message)
//     }
//   }

//   const timerFunction = (startDate) => {
//     // Get the current date and time
//     // Check if project is selected
//     if (!projectId) {
//       return // If project is not selected, exit the function
//     }
//     // console.log('startDate',startDate)
//     if (!startDate) {
//       startDate = new Date().getTime()
//     }
//     var now = new Date().getTime()

//     // Find the time elapsed since the start date
//     var elapsedTime = now - startDate

//     // Calculate the elapsed time components
//     var hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
//     var minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60))
//     var seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000)
//     hours = hours.toString().padStart(2, '0')
//     minutes = minutes.toString().padStart(2, '0')
//     seconds = seconds.toString().padStart(2, '0')
//     dispatch({
//       type: 'SET_PROJECT_CHECKIN_TIME',
//       payload: `${hours}:${minutes}:${seconds}`,
//     })
//     setTimeString(`${hours}:${minutes}:${seconds}`)
//     // localStorage.setItem('timer',`${hours}:${minutes}:${seconds}`)
//     // console.log("${hours}:${minutes}:${seconds}",`${hours}:${minutes}:${seconds}`)
//     // console.log(`${hours}:${minutes}:${seconds}`)
//     // console.log(timeString)
//   }

//   const setCapturetoLocal = (payload) => {
//     // let offlineScreenshots = localStorage.getItem('offlineScreenshots')
//     //   ? JSON.parse(localStorage.getItem('offlineScreenshots'))
//     //   : []
//     // // console.log("offlineScreenshots", offlineScreenshots);
//     // localStorage.setItem('offlineScreenshots', JSON.stringify([...offlineScreenshots, payload]))
//   }

//   const sendOfflineScreenshots = () => {
//     let offlineScreenshots = localStorage.getItem('offlineScreenshots')
//       ? JSON.parse(localStorage.getItem('offlineScreenshots'))
//       : []

//     if (offlineScreenshots.length > 0) {
//       // .format("YYYY-MM-DD--HH-MM")

//       let formData = new FormData()
//       offlineScreenshots?.map((data) => {
//         const blob = dataURItoBlob(data.file)

//         var file = new File([blob], `${data.date}.jpg`, {
//           type: 'application/octet-stream',
//         })
//         formData.append('screenshot', file)
//       })
//       formData.append('checkinId', localStorage.getItem('checkinId'))
//       addOfflineScreenshot(formData)
//     }
//   }

//   const captureImage = async ({ videoELReference, width, height, image = null }) => {
//     if (!image) {
//       let c = document.createElement('canvas'), // create a temp. canvas
//         ctx = c.getContext('2d')
//       c.width = width // set size = image, draw
//       c.height = height
//       ctx.drawImage(videoELReference, 0, 0)

//       var imgAsDataURL = c.toDataURL('application/octet-stream')
//       const blob = dataURItoBlob(imgAsDataURL)

//       let payload = {
//         file: imgAsDataURL,
//         date: Date.now(),
//       }
//       fetch(base_url, {
//         method: 'GET',
//         timeout: 1000,
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       })
//         .then(() => {
//           var file = new File(
//             [blob],
//             `${Date.now()}.jpg`,

//             {
//               type: 'application/octet-stream',
//             },
//           )
//           // sendOfflineScreenshots()

//           let formData = new FormData()
//           formData.append('screenshot', file)
//           formData.append('checkinId', localStorage.getItem('checkinId'))
//           formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
//           formData.append('project', localStorage.getItem('project'))

//           if (localStorage.getItem('checkinId')) {
//             addScreenshot(formData, payload, setCapturetoLocal)
//           }
//         })
//         .catch(() => {
//           setCapturetoLocal(payload)
//         })
//     } else {
//       const blob = dataURItoBlob(image)
//       // console.log('image', image)
//       let payload = {
//         file: image,
//         date: Date.now(),
//       }

//       // console.log('payload.file', payload?.file?.slice(0, 10))
//       fetch(base_url, {
//         method: 'GET',
//         timeout: 1000,
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       })
//         .then(() => {
//           var file = new File(
//             [blob],
//             `${Date.now()}.jpg`,

//             {
//               type: 'application/octet-stream',
//             },
//           )
//           // sendOfflineScreenshots()

//           let formData = new FormData()
//           formData.append('screenshot', file)
//           formData.append('checkinId', localStorage.getItem('checkinId'))
//           formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
//           formData.append('project', localStorage.getItem('project'))

//           if (localStorage.getItem('checkinId')) {
//             addScreenshot(formData, payload, setCapturetoLocal)
//           }
//         })
//         .catch(() => {
//           setCapturetoLocal(payload)
//         })
//     }
//   }
//   const displayMediaOptions = {
//     video: {
//       cursor: 'always',
//     },
//     audio: false,
//   }

//   const checkInStart = async () => {
//     // console.log('Start button clicked...')

//     const checkinId = localStorage.getItem('checkinId')
//     console.log('project Title: ', projectTitle)
//     const selectedProject = localStorage.getItem('project')
//     // console.log('projects - - - - - - > ',projects)
//     // console.log('selectedProject - - - - - - > ',selectedProject)
//     if (projects) {
//       let currentProject = false
//       let ele = 0
//       while (ele < projects?.length) {
//         // console.log('i i i ii ',projects[ele]?._id)
//         if (projects[ele]._id == selectedProject) {
//           // console.log('ahaha - - - - - - > ',projects[ele].title)
//           currentProject = projects[ele]
//         }
//         ele++
//       }
//       setProjectTitle(currentProject)
//       console.log('currentProject - - - - - - > ', currentProject)
//     }
//     if (!checkinId) {
//       swal('Error!', 'Please start shift checkin first!', 'error')
//       return
//     }
//     try {
//       if (!window?.electronAPI?.stopCapturing) {
//         // console.log(" nahi hay electron api")

//         setShareScreenInProgress(true)
//         let mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
//         console.log('🚀 ~ checkInStart ~ mediaStream:', mediaStream)
//         dispatch({
//           type: 'SET_MEDIA_ATIVE',
//           payload: mediaStream,
//         })

//         let track = mediaStream.getVideoTracks()[0]
//         let { width, height } = track.getSettings()
//         if (track.label.includes('1234')) {
//           console.log("12345 working")
//           dispatch(_projectCheckIn({ project: selectedProject, shiftCheckIn: checkinId }))
//           let videoELReference = document.createElement('video')
//           videoELReference.srcObject = mediaStream
//           videoELReference.play()
//           setTimer(null) // Reset timer

//           setVideoEl(videoELReference)
//           // clearInterval(timer) // Stop the timer
//           // let newTimer = setInterval(() => {
//           //   // captureImage({
//           //   //   videoELReference,
//           //   //   width,
//           //   //   height,
//           //   // })
//           //   console.log("()")
//           //   if(getCheckinProject()){
//           //     console.log('haan hai getCheckinProject()',getCheckinProject())
//           //     return () => {
//           //       clearInterval(newTimer) // Clear the new timer
//           //     }
//           //   }else{
//           //     timerFunction(new Date().getTime())
//           //   }
//           // }, 1000)
//           // setTimer(newTimer) // Set interval as timer
//           // console.log("timer chal gya")
//           // dispatch({
//           //   type: 'SET_SCREEN_INTERVAL',
//           //   payload: newTimer,
//           // })
//           let count = 0
//           // clearInterval(timer) // Stop the timer
//           let newTimer = setInterval(() => {
//             captureImage({
//               videoELReference,
//               width,
//               height,
//             })
//             setTimer(newTimer) // Set interval as timer
//             if (count < 1) {
//               // console.log('()', count)
//               timerFunction(new Date().getTime())
//             } else {
//               // console.log('clear')
//               clearInterval(newTimer) // Clear the new timer
//             }

//             count++
//           }, 1000)
//           // console.log("timer chal gya")
//           setCondition(!condition)

//           dispatch({
//             type: 'SET_SCREEN_INTERVAL',
//             payload: newTimer,
//           })

//           dispatch({
//             type: 'CURRENT_CHECK_IN_TME',
//             payload: new Date().getTime(),
//           })

//           dispatch({
//             type: 'SET_PROJECT_CHECKIN_TIME',
//             payload: null,
//           })

//           // setProjectCheckInId(getCheckinProject())
//           // console.log("ys chala")
//           setCurrentCheckIn(new Date())
//           localStorage.setItem('currentCheckInStartTime', new Date())
//           // console.log('currentCheckInStartTime', new Date())
//           setRunningProject(selectedProject)
//         }
//       } else {
//         // console.log("hay electron api")
//         dispatch(_projectCheckIn({ project: selectedProject, shiftCheckIn: checkinId }))
//         setProjectCheckInId(checkinId)
//         setRunningProject(selectedProject)
//         setProjectId(selectedProject)
//         let obj = {
//           checkinId: localStorage.getItem('checkinId'),
//           projectCheckIn: localStorage.getItem('projectCheckInId'),
//           project: localStorage.getItem('project'),
//         }
//         window.electronAPI.startCapturing(obj)
//       }
//     } catch (err) {
//       console.error(`Error: ${err}`)

//       clearInterval(timer)
//       setTimer(null)
//     } finally {
//       setShareScreenInProgress(false)
//     }
//   }
//   // useEffect(()=>{
//   //   console.log('timer - - - > ',timeString)
//   // },[timeString])

//   return (
//     <div>
//       <div>
//         {!projectCheckInId ? (
//           <Button
//             className='ant-btn css-dev-only-do-not-override-1bw12bw ant-btn-primary'
//             onClick={async () => {
//               await startShiftCheckin()
//               await checkInStart()
//             }}
//             disabled={startProjectLoading}
//           >
//             {startProjectLoading ? 'Starting...' : 'Start Project'}
//           </Button>
//         ) : (
//           // <Button onClick={openRemarksModal} style={{ fontSize:'20px'}}> {timeString}</Button>
//           <Button
//             className='ant-btn css-dev-only-do-not-override-1bw12bw ant-btn-primary'
//             onClick={openRemarksModal}
//             style={{ fontSize: '20px' }}
//           >
//             {projectTitle?.title} {projectCheckInTime}
//             {/* <StringTimer currentCheckIn={currentCheckIn}/> */}
//           </Button>
//         )}
//       </div>

//       <div>
//         <Modal
//           title={t('add-remarks')}
//           open={remarksModal}
//           footer={false}
//           onCancel={closeRemarksModal}
//           width={'600px'}
//         >
//           <Form
//             requiredMark={false}
//             layout='vertical'
//             form={form}
//             onFinish={handleRemarks}
//             // fields={[
//             //   {
//             //     name: 'remarks',
//             //     value: '',
//             //   },
//             // ]}
//           >
//             <Row gutter={[20, 0]}>
//               <Col xs={24}>
//                 <Form.Item
//                   label={t('remarks')}
//                   name={'remarks'}
//                   rules={[{ required: true, message: 'Please input remarks!' }]}
//                 >
//                   <Input.TextArea rows={4} style={{ resize: 'none' }} />
//                 </Form.Item>
//               </Col>
//             </Row>
//             <Form.Item>
//               <Button htmlType='submit' loading={btnLoading} type='primary'>
//                 {t('add-remarks')}
//               </Button>
//             </Form.Item>
//           </Form>
//         </Modal>
//       </div>
//     </div>
//   )
// }

// export default ProjectCheckIn

// import { Button } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import swal from 'sweetalert'
import {
  _projectCheckIn,
  addOfflineScreenshot,
  addScreenshot,
  projectCheckOut,
  startTask,
  getCheckins,
  setShiftCheckins,
} from '../redux'
import dataURItoBlob from '../service/dataURItoBlob'
import { Button, Col, Form, Input, Modal, Row, Spin, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { getCheckinProject, getSelectedProject } from '../functions/globals'
import { base_url } from '../config/constants'
// import StringTimer from '../components/StringTimer';

let intRun = false
const intRunChange = (val) => {
  intRun = val
  if (val == false) {
    // clearinterval
  }
}

const ProjectCheckIn = ({
  videoEL,
  isShiftOn,
  setVideoEl,
  intervalState,
  selectedProject,
  projects,
  TrackState,
  projectTitle,
  setProjectTitle,
  // condition,
  // setCondition
  // updateData
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [currentCheckIn, setCurrentCheckIn] = useState(() => {
    // Retrieve currentCheckIn from localStorage if available
    const storedCheckInStart = localStorage.getItem('currentCheckInStartTime')

    return storedCheckInStart ? new Date(storedCheckInStart) : null
  })
  const [runningProject, setRunningProject] = useState(null)
  const [projectId, setProjectId] = useState(localStorage.getItem('project'))
  const [timer, setTimer] = useState(null)
  const [screenShotstimer, setScreenShotstimer] = useState(null)
  const [projectCheckInId, setProjectCheckInId] = useState(localStorage.getItem('projectCheckInId'))
  const [remarksModal, setRemarksModal] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [condition, setCondition] = useState(false)
  const userId = localStorage.getItem('userId')
  const [startProjectLoading, setStartProjectLoading] = useState(false)
  const [shareScreenInProgress, setShareScreenInProgress] = useState(false)
  const projectCheckInTime = useSelector((state) => state.shiftCheckin.projectCheckInTime)
  const currentCheckInStartTime = useSelector((state) => state.shiftCheckin.currentCheckInStartTime)
  const projectCurrentCheckInId = useSelector((state) => state.shiftCheckin.projectCheckInId)
  const isMediaActive = useSelector((state) => state.shiftCheckin.isMediaActive)
  // const [isMediaActive, setIsMediaActive] = useState(false)

  // const [projectTitle, setProjectTitle] = useState(false)
  const [date, setDate] = useState({
    start: null,
    end: null,
  })
  const [form] = Form.useForm() // Initialize form

  const [timeString, setTimeString] = useState('')
  // console.log("🚀 ~ timeString:", timeString)
  // dispatch(setShiftCheckins(localStorage.getItem('projectCheckInId')))

  //   functions

  function stopStreamedVideo() {
    if (videoEL) {
      const streamVar = videoEL.srcObject
      if (streamVar) {
        const tracks = streamVar.getTracks()

        tracks.forEach((track) => {
          track.stop()
        })
      }
      clearInterval(intervalState)
    }
  }

  // useEffect(() => {

  //   console.log('use-Effect on intervalState, TrackState update');

  //   if (intervalState && TrackState && localStorage.getItem('checkinId')) {
  //     TrackState.onended = async () => {
  //       clearInterval(intervalState)
  //       checkOut()
  //     }
  //   }
  // }, [intervalState, TrackState])

  const handleStopCapturing = () => {
    if (window?.electronAPI?.stopCapturing) window.electronAPI.stopCapturing()
  }

  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('projectCheckInId')
  })

  // abhi isko dekhna he
  // useEffect(() => {

  //   console.log('use-Effect on currentCheckIn Update',projectCheckInId)

  //   if (!projectCheckInId) {
  //     setCurrentCheckIn(null)
  //     localStorage.removeItem('currentCheckInStartTime')
  //     console.log('aj mai uppar asman niche')
  //     return () => {
  //       clearInterval(newTimer) // Clear the new timer
  //     }
  //   }
  //   // if (getCheckinProject()) {
  //     var startDate = new Date(currentCheckIn).getTime()
  //     var newTimer = setInterval(() => {
  //       console.log("ys chala in use effect")
  //       timerFunction(startDate)
  //     }, 1000)
  //     setTimer(newTimer) // Save the new timer
  //     return () => {
  //       clearInterval(newTimer) // Clear the new timer
  //     }
  //   // }

  // }, [currentCheckIn , condition ])

  async function continueCaptureIng() {
    console.log('🚀 ~ continueCaptureIng ~ isMediaActive:', isMediaActive)
    if (isMediaActive?.active) {
      let track = isMediaActive.getVideoTracks()[0]
      let { width, height } = track.getSettings()
      let videoELReference = document.createElement('video')
      // let videoELReference = document.getElementById('very-good-id')
      videoELReference.srcObject = isMediaActive
      videoELReference.play()
      setVideoEl(videoELReference)
      captureImage({
        videoELReference,
        width,
        height,
      })
    }
  }

  useEffect(() => {
    // console.log("useEffect - - - - - > > > ",projectCheckInTime)

    if (getCheckinProject()) {
      if (!projectCheckInId) {
        // console.log('projectCheckInId',projectCheckInId)
        setProjectCheckInId(getCheckinProject())
      }
      var startDate = new Date(currentCheckIn).getTime()
      var newTimer = setInterval(async () => {
        console.log('interval 3')
        // console.log("aj mai uppar asman niche",startDate)
        timerFunction(currentCheckInStartTime)
        // alooLeloo()
      }, 1000)

      setTimer(newTimer) // Save the new timer
      return () => {
        clearInterval(newTimer) // Clear the new timer
      }
    }
    if (!getCheckinProject()) {
      setCurrentCheckIn(null)
      localStorage.removeItem('currentCheckInStartTime')
    }
  }, [currentCheckIn, condition, projectCheckInTime])

  // useEffect(() => {
  //   if (isMediaActive?.active) {
  //     console.log('🚀 ~ useEffect ~ isMediaActive?.active:', isMediaActive?.active)
  //     var newTimer = setInterval(async () => {
  //       await continueCaptureIng()
  //     }, 5000)
  //     setScreenShotstimer(newTimer)
  //   }
  // }, [isMediaActive])

  useEffect(() => {
    let newTimer

    if (isMediaActive?.active) {
      console.log('🚀 ~ useEffect ~ isMediaActive?.active:', isMediaActive?.active)

      newTimer = setInterval(async () => {
        console.log('interval 2')
        await continueCaptureIng()
      }, 300000)

      setScreenShotstimer(newTimer)
    }
    return () => {
      if (newTimer) {
        clearInterval(newTimer)
      }
    }
  }, [isMediaActive?.active])

  const openRemarksModal = () => {
    // console.log('clicked on openremark model')
    setRemarksModal(true)
  }
  const closeRemarksModal = async () => {
    await handleRemarks()
    stopStreamedVideo()
    setRemarksModal(false)
  }
  const handleRemarks = async (value) => {
    // add remarks and checkout
    setBtnLoading(true)
    await checkOut({ remarks: value?.remarks || '' })
    setRemarksModal(false)
    setBtnLoading(false)
  }

  const checkOut = async (data) => {
    localStorage.removeItem('project')
    // console.log('checkOut')
    // await sendOfflineScreenshots()
    const allDetails = await dispatch(
      projectCheckOut({
        checkIn: projectCheckInId,
        remarks: data?.remarks,
      }),
    )
    if (allDetails) {
      setProjectCheckInId('')
      setProjectId('')
      localStorage.removeItem('projectCheckInId')
    }
    stopStreamedVideo()
    setCurrentCheckIn(null)
    handleStopCapturing()
    clearInterval(timer)
    setTimer(null)
    setVideoEl(null)
    dispatch({
      type: 'CURRENT_CHECK_IN_TME',
      payload: null,
    })
    dispatch({
      type: 'SET_PROJECT_CHECKIN_TIME',
      payload: '',
    })
    dispatch({
      type: 'SET_PROJECT_CHECKIN_ID',
      payload: null,
    })
    setProjectCheckInId(null)
    setProjectTitle(false)
    setCondition(!condition)
  }

  const fetchCheckins = async () => {
    if (localStorage.getItem('userType')?.toLowerCase() !== 'moderator') {
      let allDetails = null
      if (date.start && date.end) {
        allDetails = await dispatch(
          getCheckins({
            start: moment(date.start).format('YYYY-MM-DD'),
            end: moment(date.end).format('YYYY-MM-DD'),
            userType: 'employee',
          }),
        )
        // updateData(allDetails)
      } else {
        allDetails = await dispatch(getCheckins({ userType: 'employee' }))
        console.log('at 235 projectcheckin', allDetails)
        // updateData(allDetails)
      }
      intRunChange(false)
    }
  }

  // start shift function
  const startShiftCheckin = async () => {
    // modification required
    setTimer(null)
    try {
      setStartProjectLoading(true)
      const zone = moment.tz.guess(true)
      const allDetails = await dispatch(
        startTask(
          {
            latitude: 0,
            longitude: 0,
          },
          zone,
          userId,
        ),
      )
      console.log('allDetails in startShiftCheckin', allDetails)
      // updateData(allDetails)

      fetchCheckins()
      setStartProjectLoading(false)
    } catch ({ message }) {
      console.log(message)
    }
  }

  const timerFunction = (startDate) => {
    // Get the current date and time
    // Check if project is selected
    if (!projectId) {
      return // If project is not selected, exit the function
    }
    // console.log('startDate',startDate)
    if (!startDate) {
      startDate = new Date().getTime()
    }
    var now = new Date().getTime()

    // Find the time elapsed since the start date
    var elapsedTime = now - startDate

    // Calculate the elapsed time components
    var hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    var minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60))
    var seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000)
    hours = hours.toString().padStart(2, '0')
    minutes = minutes.toString().padStart(2, '0')
    seconds = seconds.toString().padStart(2, '0')
    dispatch({
      type: 'SET_PROJECT_CHECKIN_TIME',
      payload: `${hours}:${minutes}:${seconds}`,
    })
    setTimeString(`${hours}:${minutes}:${seconds}`)
    // localStorage.setItem('timer',`${hours}:${minutes}:${seconds}`)
    // console.log("${hours}:${minutes}:${seconds}",`${hours}:${minutes}:${seconds}`)
    // console.log(`${hours}:${minutes}:${seconds}`)
    // console.log(timeString)
  }

  const setCapturetoLocal = (payload) => {
    // let offlineScreenshots = localStorage.getItem('offlineScreenshots')
    //   ? JSON.parse(localStorage.getItem('offlineScreenshots'))
    //   : []
    // // console.log("offlineScreenshots", offlineScreenshots);
    // localStorage.setItem('offlineScreenshots', JSON.stringify([...offlineScreenshots, payload]))
  }

  const sendOfflineScreenshots = () => {
    let offlineScreenshots = localStorage.getItem('offlineScreenshots')
      ? JSON.parse(localStorage.getItem('offlineScreenshots'))
      : []

    if (offlineScreenshots.length > 0) {
      // .format("YYYY-MM-DD--HH-MM")

      let formData = new FormData()
      offlineScreenshots?.map((data) => {
        const blob = dataURItoBlob(data.file)

        var file = new File([blob], `${data.date}.jpg`, {
          type: 'application/octet-stream',
        })
        formData.append('screenshot', file)
      })
      formData.append('checkinId', localStorage.getItem('checkinId'))

      addOfflineScreenshot(formData)
    }
  }

  // const captureImage = async ({ videoELReference, width, height, image = null }) => {
  //   if (!image) {
  //     // let c = document.createElement('canvas'), // create a temp. canvas
  //     //   ctx = c.getContext('2d')
  //     // c.width = width // set size = image, draw
  //     // c.height = height
  //     // ctx.drawImage(videoELReference, 0, 0)

  //     let c = document.createElement('canvas');
  //     let ctx = c.getContext('2d');
  //     c.width = videoELReference.videoWidth;
  //     c.height = videoELReference.videoHeight;

  //     ctx.clearRect(0, 0, c.width, c.height);

  //     // Draw the video frame
  //     ctx.drawImage(videoELReference, 0, 0, c.width, c.height);

  //     var imgAsDataURL = c.toDataURL('application/octet-stream');
  //     console.log("imageDataUr",imgAsDataURL);
  //     const blob = dataURItoBlob(imgAsDataURL)
  //     addImage(imgAsDataURL);

  //     let payload = {
  //       file: imgAsDataURL,
  //       date: Date.now(),
  //     }
  //     fetch(base_url, {
  //       method: 'GET',
  //       timeout: 1000,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //       .then(() => {
  //         var file = new File(
  //           [blob],
  //           `${Date.now()}.jpg`,

  //           {
  //             type: 'application/octet-stream',
  //           },
  //         )
  //         // sendOfflineScreenshots()

  //         let formData = new FormData()
  //         formData.append('screenshot', file)
  //         formData.append('checkinId', localStorage.getItem('checkinId'))
  //         formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
  //         formData.append('project', localStorage.getItem('project'))

  //         for (var pair of formData.entries()) {
  //           console.log("formData", pair[0] + ' - ' + pair[1]);
  //         }

  //         if (localStorage.getItem('checkinId')) {
  //           addScreenshot(formData, payload, setCapturetoLocal)
  //         }
  //       })
  //       .catch(() => {
  //         setCapturetoLocal(payload)
  //       })
  //   } else {
  //     console.log("capture image else")
  //     const blob = dataURItoBlob(image)
  //     // console.log('image', image)
  //     let payload = {
  //       file: image,
  //       date: Date.now(),
  //     }

  //     // console.log('payload.file', payload?.file?.slice(0, 10))
  //     fetch(base_url, {
  //       method: 'GET',
  //       timeout: 1000,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //       .then(() => {
  //         var file = new File(
  //           [blob],
  //           `${Date.now()}.jpg`,

  //           {
  //             type: 'application/octet-stream',
  //           },
  //         )
  //         // sendOfflineScreenshots()

  //         let formData = new FormData()
  //         formData.append('screenshot', file)
  //         formData.append('checkinId', localStorage.getItem('checkinId'))
  //         formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
  //         formData.append('project', localStorage.getItem('project'))

  //         if (localStorage.getItem('checkinId')) {
  //           addScreenshot(formData, payload, setCapturetoLocal)
  //         }
  //       })
  //       .catch(() => {
  //         setCapturetoLocal(payload)
  //       })
  //   }
  // }

  const waitForVideoReady = (videoELReference) => {
    return new Promise((resolve) => {
      const checkReady = () => {
        if (videoELReference.readyState === videoELReference.HAVE_ENOUGH_DATA) {
          resolve()
        } else {
          setTimeout(checkReady, 100) // Check every 100ms until ready
        }
      }
      checkReady()
    })
  }

  const captureImage = async ({ videoELReference, width, height, image = null }) => {
    if (!image) {
      await waitForVideoReady(videoELReference) // Wait until video is ready

      let c = document.createElement('canvas')
      let ctx = c.getContext('2d')

      // Ensure canvas is cleared and set to correct dimensions before drawing
      c.width = videoELReference.videoWidth
      c.height = videoELReference.videoHeight
      ctx.clearRect(0, 0, c.width, c.height)

      // Draw the video frame
      ctx.drawImage(videoELReference, 0, 0, c.width, c.height)

      let imgAsDataURL = c.toDataURL('image/jpeg') // Use a correct MIME type for images
      console.log('imageDataUrl', imgAsDataURL)

      if (imgAsDataURL) {
        const blob = dataURItoBlob(imgAsDataURL)

        let payload = {
          file: imgAsDataURL,
          date: Date.now(),
        }

        try {
          let file = new File([blob], `${Date.now()}.jpg`, { type: 'image/jpeg' })

          let formData = new FormData()
          formData.append('screenshot', file)
          formData.append('checkinId', localStorage.getItem('checkinId'))
          formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
          formData.append('project', localStorage.getItem('project'))

          if (localStorage.getItem('checkinId')) {
            addScreenshot(formData, payload, setCapturetoLocal)
          }
        } catch (error) {
          setCapturetoLocal(payload)
        }
      } else {
        console.warn('No image data captured.')
      }
    } else {
      // Handle the case when an image is passed
      const blob = dataURItoBlob(image)

      let payload = {
        file: image,
        date: Date.now(),
      }

      try {
        let file = new File([blob], `${Date.now()}.jpg`, { type: 'image/jpeg' })

        let formData = new FormData()
        formData.append('screenshot', file)
        formData.append('checkinId', localStorage.getItem('checkinId'))
        formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
        formData.append('project', localStorage.getItem('project'))

        if (localStorage.getItem('checkinId')) {
          addScreenshot(formData, payload, setCapturetoLocal)
        }
      } catch (error) {
        setCapturetoLocal(payload)
      }
    }
  }

  const displayMediaOptions = {
    video: {
      cursor: 'always',
    },
    audio: false,
  }

  const checkInStart = async () => {
    // console.log('Start button clicked...')

    const checkinId = localStorage.getItem('checkinId')
    console.log('project Title: ', projectTitle)
    const selectedProject = localStorage.getItem('project')
    // console.log('projects - - - - - - > ',projects)
    // console.log('selectedProject - - - - - - > ',selectedProject)
    if (projects) {
      let currentProject = false
      let ele = 0
      while (ele < projects?.length) {
        // console.log('i i i ii ',projects[ele]?._id)
        if (projects[ele]._id == selectedProject) {
          // console.log('ahaha - - - - - - > ',projects[ele].title)
          currentProject = projects[ele]
        }
        ele++
      }
      setProjectTitle(currentProject)
      console.log('currentProject - - - - - - > ', currentProject)
    }
    if (!checkinId) {
      swal('Error!', 'Please start shift checkin first!', 'error')
      return
    }
    try {
      if (!window?.electronAPI?.stopCapturing) {
        // console.log(" nahi hay electron api")

        setShareScreenInProgress(true)
        let mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
        console.log('🚀 ~ checkInStart ~ mediaStream:', mediaStream)
        dispatch({
          type: 'SET_MEDIA_ATIVE',
          payload: mediaStream,
        })

        let track = mediaStream.getVideoTracks()[0]
        let { width, height } = track.getSettings()
        if (track.label.includes('screen')) {
          dispatch(_projectCheckIn({ project: selectedProject, shiftCheckIn: checkinId }))
          let videoELReference = document.createElement('video')
          // let videoELReference = document.getElementById('very-good-id')
          videoELReference.srcObject = mediaStream
          videoELReference.play()
          console.log('image called first time only')

          setTimeout(() => {
            captureImage({
              videoELReference,
              width,
              height,
            })
          }, 1000)
          setTimer(null) // Reset timer

          // setVideoEl(videoELReference)
          // clearInterval(timer) // Stop the timer
          // let newTimer = setInterval(() => {
          //   // captureImage({
          //   //   videoELReference,
          //   //   width,
          //   //   height,
          //   // })
          //   console.log("()")
          //   if(getCheckinProject()){
          //     console.log('haan hai getCheckinProject()',getCheckinProject())
          //     return () => {
          //       clearInterval(newTimer) // Clear the new timer
          //     }
          //   }else{
          //     timerFunction(new Date().getTime())
          //   }
          // }, 1000)
          // setTimer(newTimer) // Set interval as timer
          // console.log("timer chal gya")
          // dispatch({
          //   type: 'SET_SCREEN_INTERVAL',
          //   payload: newTimer,
          // })
          let count = 0
          // clearInterval(timer) // Stop the timer
          let newTimer = setInterval(() => {
            console.log('interval 1')
            // captureImage({
            //   videoELReference,
            //   width,
            //   height,
            // })
            setTimer(newTimer) // Set interval as timer
            if (count < 1) {
              // console.log('()', count)
              timerFunction(new Date().getTime())
            } else {
              // console.log('clear')
              clearInterval(newTimer) // Clear the new timer
            }

            count++
          }, 1000)
          // console.log("timer chal gya")
          setCondition(!condition)

          dispatch({
            type: 'SET_SCREEN_INTERVAL',
            payload: newTimer,
          })

          dispatch({
            type: 'CURRENT_CHECK_IN_TME',
            payload: new Date().getTime(),
          })

          dispatch({
            type: 'SET_PROJECT_CHECKIN_TIME',
            payload: null,
          })

          // setProjectCheckInId(getCheckinProject())
          // console.log("ys chala")
          setCurrentCheckIn(new Date())
          localStorage.setItem('currentCheckInStartTime', new Date())
          // console.log('currentCheckInStartTime', new Date())
          setRunningProject(selectedProject)
        }
      } else {
        // console.log("hay electron api")
        dispatch(_projectCheckIn({ project: selectedProject, shiftCheckIn: checkinId }))
        setProjectCheckInId(checkinId)
        setRunningProject(selectedProject)
        setProjectId(selectedProject)
        let obj = {
          checkinId: localStorage.getItem('checkinId'),
          projectCheckIn: localStorage.getItem('projectCheckInId'),
          project: localStorage.getItem('project'),
        }
        window.electronAPI.startCapturing(obj)
      }
    } catch (err) {
      console.error(`Error: ${err}`)

      clearInterval(timer)
      setTimer(null)
    } finally {
      setShareScreenInProgress(false)
    }
  }
  // useEffect(()=>{
  //   console.log('timer - - - > ',timeString)
  // },[timeString])

  const openModalByRemarks = () => {
    openRemarksModal()
    stopStreamedVideo()

    stopVideoStream(isMediaActive)
  }

  function stopVideoStream(stream) {
    if (stream) {
      const tracks = stream.getTracks()
      tracks.forEach((track) => track.stop())
    }
  }

  return (
    <div>
      {/* <video id='very-good-id' src='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4' style={{ height: '150px', width: '150px' }} /> */}
      <div>
        {!projectCheckInId ? (
          <Button
            className='ant-btn css-dev-only-do-not-override-1bw12bw ant-btn-primary'
            onClick={async () => {
              await startShiftCheckin()
              await checkInStart()
            }}
            disabled={startProjectLoading}
          >
            {startProjectLoading ? 'Starting...' : 'Start Project'}
          </Button>
        ) : (
          // <Button onClick={openRemarksModal} style={{ fontSize:'20px'}}> {timeString}</Button>
          <Button
            className='ant-btn css-dev-only-do-not-override-1bw12bw ant-btn-primary'
            // onClick={openRemarksModal}
            onClick={openModalByRemarks}
            style={{ fontSize: '20px' }}
          >
            {projectTitle?.title} {projectCheckInTime}
            {/* <StringTimer currentCheckIn={currentCheckIn}/> */}
          </Button>
        )}
      </div>

      <div>
        <Modal
          title={t('add-remarks')}
          open={remarksModal}
          footer={false}
          onCancel={closeRemarksModal}
          width={'600px'}
        >
          <Form
            requiredMark={false}
            layout='vertical'
            form={form}
            onFinish={handleRemarks}
            // fields={[
            //   {
            //     name: 'remarks',
            //     value: '',
            //   },
            // ]}
          >
            <Row gutter={[20, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t('remarks')}
                  name={'remarks'}
                  rules={[{ required: true, message: 'Please input remarks!' }]}
                >
                  <Input.TextArea rows={4} style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button htmlType='submit' loading={btnLoading} type='primary'>
                {t('add-remarks')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  )
}

export default ProjectCheckIn
