import swal from 'sweetalert'
import { notification } from 'antd'
import privateAPI, { publicAPI, attachToken } from '../../config/constants'
import * as types from '../types/generalTypes'

export const getAllProjects = (referenceFilter) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/project/get-all`, {
        referenceFilter,
      })
      if (res) {
        dispatch({
          type: types.GET_ALL_PROJECTS,
          payload: res.data,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

// export const getInternationalProjects = (referenceFilter) => {
//   return async (dispatch) => {
//     try {
//       attachToken();
//       const res = await privateAPI.post(`/project/get-all/`, {
//         referenceFilter,
//       });
//       if (res) {
//         dispatch({
//           type: types.GET_ALL_PROJECTS,
//           payload: res.data,
//         });
//       }
//     } catch (err) {
//       //console.log(err);
//       swal("", err?.response?.data?.message || "Server Error", "error");
//     }
//   };
// };

export const getAdminProject = (data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/project/get`, data)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getUserProject = (data) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/project/get-employee`, data)
      if (res) {
        dispatch({
          type: types.GET_PROJECT_DETAILS,
          payload: res.data,
        })
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getProjectUsers = (id) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.get(`/project/project-users/${id}`)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const createProject = (values) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/project/create`, values)
      if (res) {
        dispatch(getAllProjects())
        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const updateProject = (values) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/project/update`, values)
      if (res) {
        notification.success({
          description: res.data.message,
          duration: 2,
        })
        return true
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getAllEmployeeProjects = () => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.get(`/project/get-all-employee`)
      if (res) {
        dispatch({
          type: types.GET_ALL_PROJECTS,
          payload: res.data,
        })
        return res.data
      }
    } catch (err) {
      swal('', err?.response?.data?.message || 'Server Error', 'error')
      return err?.response?.data?.message
    }
  }
}

export const getProjectCosting = (projectId, data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/project/get-costing/${projectId}`, data)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getProjectDailyCosting = (projectId, data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/project/get-daily-costing/${projectId}`, data)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getByResource = async (payload) => {
  //  console.log(payload)
  try {
    attachToken()
    const res = await privateAPI.post(`/report/get-by-resource`, payload)
    // console.log(payload)
    if (res) {
      return res.data
    }
  } catch (err) {
    // console.log(err);
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const getReportsAllProjects = async (payload) => {
  try {
    attachToken()
    const res = await privateAPI.post(`/report/get-all-projects`, payload)
    if (res) {
      return res.data
    }
  } catch (err) {
    // console.log(err);
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const getReportsAllEmployees = async (payload) => {
  try {
    attachToken()
    const res = await privateAPI.post(`/report/get-all-employees`, payload)
    if (res) {
      return res.data
    }
  } catch (err) {
    // console.log(err);
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const getUserTimelineScreenshots = () => {
  console.log('getUserTimelineScreenshots')

  return async (dispatch) => {
    try {
      let res
      attachToken()
      if (localStorage.getItem('userType') === 'employee') {
        res = await privateAPI.get(`/screenshot/get-user-check-ins`)
      } else {
        res = await privateAPI.get(`/screenshot/get-user-check-ins-m`)
      }

      console.log('res +_+_+_+_+_+_+_+_+ ', res?.data)

      if (res) {
        dispatch({
          type: types.SET_SHIFT_CHECKINS,
          payload: res.data,
        })
      }
    } catch (err) {
      console.log(err)
      console.log('getUserTimelineScreenshots')

      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const startTask = (cordinates, zone, userId) => {
  return async (dispatch) => {
    try {
      let res
      console.log('/screenshot/start', zone)

      // attachToken()
      if (localStorage.getItem('userType') === 'employee') {
        res = await privateAPI.post(`/screenshot/start`, {
          cordinates,
          zone,
        })
      } else {
        res = await privateAPI.post(`/screenshot/start-m`, {
          cordinates,
          zone,
        })
      }
      if (res) {
        console.log('/screenshot/start', res.data)
        dispatch(getUserTimelineScreenshots())
        localStorage.setItem('checkinId', res.data?.doc._id)
        notification.success({
          description: res.data.message,
          duration: 2,
        })

        let allDetails = await getUserDailyDetails({ user: userId })
        console.log('all details', allDetails)
        return allDetails
      }
    } catch (err) {
      console.log(err)
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const endTask = (force, userId) => {
  return async (dispatch) => {
    try {
      let res
      attachToken()
      if (localStorage.getItem('userType') === 'employee') {
        res = await privateAPI.post(`/screenshot/end`, { force })
      } else {
        res = await privateAPI.post(`/screenshot/end-m`, { force })
      }
      if (res) {
        dispatch(getUserTimelineScreenshots())
        localStorage.removeItem('checkinId')

        notification.success({
          description: res.data.message,
          duration: 2,
        })
        let allDetails = await getUserDailyDetails({ user: userId })
        return allDetails
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const addScreenshot = async (formdata, payload, setLocalFunction) => {
  try {
    attachToken()
    if (localStorage.getItem('userType') === 'employee') {
      console.log('formData', formdata)

      await privateAPI.post(`/screenshot/add-screenshot`, formdata)
    } else {
      await privateAPI.post(`/screenshot/add-screenshot-m`, formdata)
    }
  } catch (err) {
    setLocalFunction(payload)
    // swal("", err?.response?.data?.message || "Server Error", "error");
  }
}

export const addOfflineScreenshot = async (payload) => {
  try {
    let res
    attachToken()
    if (localStorage.getItem('userType') === 'employee') {
      res = await privateAPI.post(`/screenshot/add-offline-screenshot`, payload)
    } else {
      res = await privateAPI.post(`/screenshot/add-offline-screenshot-m`, payload)
    }
    if (res) {
      localStorage.setItem('offlineScreenshots', JSON.stringify([]))
    }
  } catch (err) {
    // console.log(err);
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const getUserScreenshots = async (payload) => {
  try {
    attachToken()
    const res = await privateAPI.post(`/screenshot/get-screenshot`, payload)
    if (res) {
      return res.data
    }
  } catch (err) {
    // console.log(err);
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const getUserDailyDetails = async (payload) => {
  try {
    attachToken()
    const res = await privateAPI.post(`/screenshot/getuserdailydetails`, payload)
    if (res) {
      return res.data
    }
  } catch (err) {
    // console.log(err);
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}
export const getCheckins = (data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(
        data?.userType === 'employee' ? `/screenshot/getcheckins` : '/screenshot/getCheckinsSnaps',
        data,
      )
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getProjectPresence = async (payload) => {
  try {
    // console.log(payload)
    attachToken()
    const res = await privateAPI.get(`/project/presence/${payload}`)
    if (res) {
      return res.data
    }
  } catch (err) {
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const addTimelineCsv = async (payload) => {
  try {
    // console.log(payload)
    attachToken()
    const res = await privateAPI.post(`/timeline/bulk-create`, payload)
    if (res) {
      return res.data
    }
  } catch (err) {
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const _projectCheckIn = (payload) => {
  return async () => {
    try {
      // Check if payload is null or undefined
      if (!payload || !payload.project || !payload.shiftCheckIn) {
        throw new Error('Required payload properties are missing')
      }

      attachToken()
      console.log(payload)
      const res = await privateAPI.post(`/project/checkIn`, payload)
      if (res) {
        notification.success({
          description: res.data.message,
          duration: 2,
        })
        // dispatch({
        //   type: types.SET_SHIFT_CHECKINS,
        //   payload: res.data,
        // })
        localStorage.setItem('projectCheckInId', res.data?.data?._id)
        localStorage.setItem('project', res.data?.data?.project)
        return res.data
      }
    } catch (err) {
      // Handle the error if required properties in payload are missing
      if (err.message === 'Required payload properties are missing') {
        swal('', 'Please Refresh the page and check in again', 'error')
      } else {
        swal('', err?.response?.data?.message || 'Server Error', 'error')
      }
    }
  }
}

// export const _projectCheckIn = (payload) => {
//   return async () => {
//     try {
//       attachToken()
//       console.log(payload)
//       const res = await privateAPI.post(`/project/checkIn`, payload)
//       if (res) {
//         notification.success({
//           description: res.data.message,
//           duration: 2,
//         })
//         localStorage.setItem('projectCheckInId', res.data?.data?._id)
//         localStorage.setItem('project', res.data?.data?.project)
//         return res.data
//       }
//     } catch (err) {
//       swal('', err?.response?.data?.message || 'Server Errorssssss', 'errorsssssss')
//     }
//   }
// }

export const projectCheckOut = (payload) => {
  return async (dispatch) => {
    try {
      // console.log(payload)
      attachToken()
      const res = await privateAPI.post(`/project/checkOut`, payload)
      if (res) {
        dispatch({
          type: types.SET_PROJECT_CHECKIN,
          payload: {},
        })

        notification.success({
          description: res.data.message,
          duration: 2,
        })
        localStorage.removeItem('projectCheckInId')
        localStorage.removeItem('project')
        return res.data
      }
    } catch (err) {
      swal('Your are not currently checkedin, Try again by refreshing the page')
      // swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getProjectCheckIns = (payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/project/getCheckIns`, payload)
      if (res) {
        return res.data
      }
    } catch (err) {
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}
