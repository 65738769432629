import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'

import Dashboard from '../pages/Dashboard'
import Login from '../pages/Login'
import AdminProjects from '../pages/AdminProject'
import AdminEmployees from '../pages/AdminEmployee'
import ProjectDetail from '../pages/ProjectDetail'
import UserProject from '../pages/UserProject'
import Moderators from '../pages/Moderator'
import ProjectSummary from '../pages/ProjectSummary'
import EmployeeSummary from '../pages/EmployeeSummary'
import SummaryOwnEmployee from '../pages/SummaryOwnEmployee'
import Reports from '../pages/Reports'
import PrivateRoutes from '../config/PrivateRoutes'
import Calendar from '../pages/Calendar'
import Todos from '../pages/Todos'
import EmployeeScreenshots from '../pages/EmployeeScreenshots'
import EmployeeAttendance from '../pages/EmployeeAttendance'
import Roles from '../pages/Roles.js'
import Expenses from '../pages/Expenses'
import Notifications from '../pages/Notifications.js'
import { useState, useEffect } from 'react'
import Logs from '../pages/Logs'
import CreateNotifications from '../pages/CreateNotifications.js'
import DeletedTodos from '../pages/DeletedTodos'
import EmployeeCheckins from '../pages/Checkins.js'
import AdminRoutes from './AdminRoutes'
import EmployeeRoutes from './EmployeeRoutes'
import ForgotPassword from '../pages/ForgotPassword.js'
import Accessories from '../pages/Accessories'
import ResetPassword from '../pages/ResetPassword.js'
import Settings from '../pages/Settings.js'
import { useSelector } from 'react-redux'
import Leave from '../pages/Employee/Leave'
import AdminLeave from '../pages/Admin/Leave'
import AdminAnnouncement from '../pages/Admin/Annoucement'
import AdminHR from '../pages/Admin/HR/index'
import Ticket from '../pages/Employee/Ticket'
import CheckinNew from '../pages/CheckinNew.js'

const Routes = () => {
  const [intervalState, setIntervalState] = useState(null)
  const [videoEL, setVideoEl] = useState(null)
  const [TrackState, setTrackState] = useState(null)
  const data = useSelector((state) => state.admin.moderator)
  let type = data?.adminType
  let roles = data?.roles?.roles
  if (data == null) {
    type = localStorage.getItem('userType')
    let mod = JSON.parse(localStorage.getItem('moderator'))
    roles = mod?.roles?.roles
  }
  const [projectTitle, setProjectTitle] = useState(false)
  const [defaultValue, setDefaultValue] = useState('project')
  const [isShift, setIsShift] = useState(false)
  const [condition, setCondition] = useState(false)
  const [dataForCheckInNew, setData] = useState()
  const [checkinHistory, setCheckinHistory] = useState(false)

  useEffect(() => {
    console.log('checkinnew use-Effect project title update', projectTitle)
    if (projectTitle) {
      setDefaultValue(projectTitle.title)
      console.log('default =- -= - = - = - = - ', projectTitle.title)
    }
    // setTimeString(localStorage?.getItem('timer'))
  }, [projectTitle])

  useEffect(() => {
    console.log('CheckIn History', checkinHistory)
  }, [checkinHistory])

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {/* General Route */}
          <Route path='/' exact component={Login} />
          <Route path='/forgotPassword' exact component={ForgotPassword} />
          <Route path='/resetPassword' exact component={ResetPassword} />
          <PrivateRoutes
            path='/moderator/checkin'
            exact
            component={() => {
              return (
                <EmployeeCheckins
                  intervalState={intervalState}
                  setIntervalState={setIntervalState}
                  videoEL={videoEL}
                  setVideoEl={setVideoEl}
                  TrackState={TrackState}
                  setTrackState={setTrackState}
                />
              )
            }}
          />

          {/* {console.log('type', type)} */}
          {/* Admin Route */}
          {type !== 'moderator' && (
            <>
              {/* Admin Routes */}
              <>
                <AdminRoutes path='/dashboard' exact component={Dashboard} />
                <AdminRoutes path='/project/:id' exact component={ProjectDetail} />
                <AdminRoutes path='/calendar' exact component={Calendar} />

                {/* Admin AdminRoutes */}
                <AdminRoutes path='/admin/dashboard' exact component={Dashboard} />
                <AdminRoutes path='/admin/projects' exact component={AdminProjects} />
                <AdminRoutes path='/admin/project/:id' exact component={ProjectDetail} />
                <AdminRoutes path='/admin/project/summary/:id' exact component={ProjectSummary} />
                <AdminRoutes path='/admin/employees' exact component={AdminEmployees} />
                <AdminRoutes path='/admin/attendance' exact component={EmployeeAttendance} />
                <AdminRoutes path='/admin/checkins' exact component={EmployeeScreenshots} />
                <AdminRoutes path='/admin/employee/summary/:id' exact component={EmployeeSummary} />
                {/* <AdminRoutes path='/admin/moderators' exact component={Moderators} /> */}
                <AdminRoutes path='/admin/reports' exact component={Reports} />
                <AdminRoutes path='/admin/expenses' exact component={Expenses} />
                <AdminRoutes path='/admin/leave' exact component={AdminLeave} />
                {/* <AdminRoutes path='/admin/announcement' exact component={AdminAnnouncement} /> */}
                {/* <AdminRoutes path='/admin/hr' exact component={AdminHR} /> */}
                {/* <AdminRoutes path='/admin/accessories' exact component={Accessories} /> */}

                {/* <AdminRoutes path='/admin/roles' exact component={Roles} /> */}
                {/* <AdminRoutes path='/admin/logs' exact component={Logs} /> */}

                <AdminRoutes
                  path='/admin/createNotification'
                  exact
                  component={CreateNotifications}
                />
                <AdminRoutes path='/admin/deleted-todos/:id' exact component={DeletedTodos} />
                <AdminRoutes path='/admin/settings' exact component={Settings} />
              </>
              {/* Employee Routes */}
              <>
                <EmployeeRoutes
                  path='/employee/projects'
                  exact
                  component={() => {
                    return (
                      <UserProject
                        intervalState={intervalState}
                        setIntervalState={setIntervalState}
                        videoEL={videoEL}
                        setVideoEl={setVideoEl}
                        TrackState={TrackState}
                        setTrackState={setTrackState}
                      />
                    )
                  }}
                />
                <EmployeeRoutes path='/todo' exact component={Todos} />
                <EmployeeRoutes path='/project-e/:id' exact component={ProjectDetail} />
                <EmployeeRoutes path='/employee/summary' exact component={SummaryOwnEmployee} />
                <EmployeeRoutes path='/notifications' exact component={Notifications} />
                <EmployeeRoutes path='/leave' exact component={Leave} />
                <EmployeeRoutes path='/announcement' exact component={AdminAnnouncement} />
                <EmployeeRoutes path='/ticket' exact component={Ticket} />
                <EmployeeRoutes
                  path='/employee/checkin'
                  exact
                  component={() => {
                    return (
                      <EmployeeCheckins
                        intervalState={intervalState}
                        setIntervalState={setIntervalState}
                        videoEL={videoEL}
                        setVideoEl={setVideoEl}
                        TrackState={TrackState}
                        setTrackState={setTrackState}
                      />
                    )
                  }}
                />
                <EmployeeRoutes
                  path='/employee/checkinNew'
                  exact
                  component={() => {
                    return (
                      <CheckinNew
                        intervalState={intervalState}
                        setIntervalState={setIntervalState}
                        videoEL={videoEL}
                        setVideoEl={setVideoEl}
                        TrackState={TrackState}
                        setTrackState={setTrackState}
                        projectTitle={projectTitle}
                        setProjectTitle={setProjectTitle}
                        defaultValue={defaultValue}
                        // timeString={timeString}
                        // setTimeString={setTimeString}
                        condition={condition}
                        setCondition={setCondition}
                        isShift={isShift}
                        setIsShift={setIsShift}
                        data={dataForCheckInNew}
                        setData={setData}
                        checkinHistory={checkinHistory}
                        setCheckinHistory={setCheckinHistory}
                      />
                    )
                  }}
                />
              </>
            </>
          )}

          {/* Moderator PrivateRoutes */}
          {type === 'moderator' && (
            <>
              <PrivateRoutes path='/todo' exact component={Todos} />
              <PrivateRoutes path='/project/:id' exact component={ProjectDetail} />
              <PrivateRoutes path='/calendar' exact component={Calendar} />

              {/* Admin PrivateRoutes */}
              {roles?.includes('dashboard') && (
                <PrivateRoutes path='/admin/dashboard' exact component={Dashboard} />
              )}
              {roles?.includes('projects') && (
                <>
                  <PrivateRoutes path='/admin/projects' exact component={AdminProjects} />
                  <PrivateRoutes path='/admin/project/:id' exact component={ProjectDetail} />
                  <PrivateRoutes
                    path='/admin/project/summary/:id'
                    exact
                    component={ProjectSummary}
                  />
                </>
              )}
              {roles?.includes('employees') && (
                <>
                  <PrivateRoutes path='/admin/employees' exact component={AdminEmployees} />
                  <PrivateRoutes
                    path='/admin/employee/summary/:id'
                    exact
                    component={EmployeeSummary}
                  />
                  <PrivateRoutes path='/employee/summary' exact component={SummaryOwnEmployee} />
                </>
              )}
              {roles?.includes('attendance') && (
                <PrivateRoutes path='/admin/attendance' exact component={EmployeeAttendance} />
              )}
              {roles?.includes('checkins') && (
                <PrivateRoutes path='/admin/checkins' exact component={EmployeeScreenshots} />
              )}
              {roles?.includes('moderators') && (
                <PrivateRoutes path='/admin/moderators' exact component={Moderators} />
              )}
              {roles?.includes('reports') && (
                <PrivateRoutes path='/admin/reports' exact component={Reports} />
              )}
              {roles?.includes('expenses') && (
                <PrivateRoutes path='/admin/expenses' exact component={Expenses} />
              )}
              {roles?.includes('roles') && (
                <PrivateRoutes path='/admin/roles' exact component={Roles} />
              )}
              {roles?.includes('leave') && (
                <PrivateRoutes path='/admin/leave' exact component={AdminLeave} />
              )}
              {roles?.includes('annoucement') && (
                <PrivateRoutes path='/moderator/announcement' exact component={AdminAnnouncement} />
              )}
              {roles?.some((v) => v?.includes('hr-')) && (
                <PrivateRoutes path='/admin/hr' exact component={AdminHR} />
              )}
            </>
          )}
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

export default Routes
