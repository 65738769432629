import {
  SET_PROJECT_CHECKIN_ID,
  SET_SHIFT_CHECKINS,
  SET_START_TIME,
  CURRENT_CHECK_IN_TME,
  SET_PROJECT_CHECKIN_TIME,
  SET_MEDIA_ATIVE,
} from '../types/generalTypes'

const initialState = {
  checkins: [],
  projectCheckInId: '',
  currentCheckInStartTime: null,
  projectCheckInTime: '',
}

const shiftCheckin = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_SHIFT_CHECKINS:
      return {
        ...state,
        checkins: payload,
      }
    case SET_START_TIME:
      return {
        ...state,
        checkins: payload,
      }
    case CURRENT_CHECK_IN_TME:
      return {
        ...state,
        currentCheckInStartTime: payload,
      }
    case SET_PROJECT_CHECKIN_ID:
      return {
        ...state,
        projectCheckInId: payload,
      }
    case SET_PROJECT_CHECKIN_TIME:
      return {
        ...state,
        projectCheckInTime: payload,
      }
    case SET_MEDIA_ATIVE:
      return {
        ...state,
        isMediaActive: payload,
      }

    default:
      return state
  }
}

export default shiftCheckin
